var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import Downshift from "downshift";
import { MenuItem, Paper, InputBase, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./AutoCompleteStyles";
const AutoComplete = (props) => {
    const { entries = [], onChange = () => console.log("no onChange handler given to the autoComplete"), placeholder = "Zoek op straatnaam...", noResult = "Geen resultaten gevonden...", classes, id, } = props;
    const [inputValue, setInputValue] = useState("");
    const noResultItem = { label: noResult, value: noResult };
    return (React.createElement(Downshift, { onChange: (item) => {
            const splitted = item.value.split(",");
            if (splitted && splitted.length > 0) {
                setInputValue(splitted[0] + " ");
            }
            onChange(item);
        }, itemToString: (item) => (item ? item.value : ""), defaultHighlightedIndex: 0 }, ({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen, selectedItem, reset }) => (React.createElement("div", { className: classes.container },
        renderInput({
            id: id,
            fullWidth: true,
            classes,
            placeholder,
            value: inputValue,
            InputProps: getInputProps({
                onChange: (e) => {
                    const searchValue = e.target.value;
                    setInputValue(searchValue);
                    reset();
                    if (props.apiCall) {
                        props.apiCall(searchValue);
                    }
                },
            }),
        }),
        React.createElement("div", Object.assign({}, getMenuProps()), isOpen ? (React.createElement(Paper, { className: classes.paper, square: true }, entries.length > 0
            ? entries.map((suggestion, index) => renderSuggestion({
                suggestion,
                index,
                itemProps: getItemProps({ item: suggestion }),
                highlightedIndex,
                selectedItem,
            }))
            : renderNoResult({
                suggestion: noResultItem,
                index: 0,
                highlightedIndex,
                selectedItem,
            }))) : null)))));
};
function renderInput(inputProps) {
    const { InputProps, classes, ref, placeholder, value } = inputProps, other = __rest(inputProps, ["InputProps", "classes", "ref", "placeholder", "value"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.searchIcon },
            React.createElement(SearchIcon, null)),
        React.createElement(InputBase, Object.assign({}, Object.assign({ inputRef: ref }, InputProps), other, { placeholder: placeholder, classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
            }, value: value }))));
}
const renderSuggestion = (props) => {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem } = props;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem ? (selectedItem.label || "").indexOf(suggestion.label) > -1 : false;
    return (React.createElement(MenuItem, Object.assign({}, itemProps, { key: suggestion.label, selected: isHighlighted, component: "div", style: {
            fontWeight: isSelected ? 500 : 400,
        } }), suggestion.label));
};
const renderNoResult = (props) => {
    const { suggestion } = props;
    return (React.createElement(MenuItem, { key: suggestion.label, component: "div", style: {
            fontWeight: 400,
        } }, suggestion.label));
};
export default withStyles(styles)(AutoComplete);

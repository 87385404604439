import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "users.search",
    defaultMessage: "Gebruiker zoeken...",
  },
  addtousergroup: {
    id: "users.addtousergroup",
    defaultMessage: "Toevoegen aan gebruikersgroep",
  },
  addtoorganization: {
    id: "users.addtoorganization",
    defaultMessage: "Toevoegen aan organisatie",
  },
  tableheading1: {
    id: "users.tableheading1",
    defaultMessage: "Gebruiker",
  },
  tableheading1short: {
    id: "users.tableheading1short",
    defaultMessage: "Gebr.",
  },
  tableheading2: {
    id: "users.tableheading2",
    defaultMessage: "E-mail",
  },
  tableheading3: {
    id: "users.tableheading3",
    defaultMessage: "Admin",
  },
  tableheading3short: {
    id: "users.tableheading3short",
    defaultMessage: "Admin",
  },
  tableheading4: {
    id: "users.tableheading4",
    defaultMessage: "SSO",
  },
  tableheading4short: {
    id: "users.tableheading4short",
    defaultMessage: "SSO",
  },
  tableheading5: {
    id: "users.tableheading5",
    defaultMessage: "Org",
  },
  tableheading5short: {
    id: "users.tableheading5short",
    defaultMessage: "Org",
  },
  details: {
    id: "users.details",
    defaultMessage: "Details",
  },
  rowsamount: {
    id: "users.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  deletedialogtitle: {
    id: "users.deletedialogtitle",
    defaultMessage: "Gebruikers verwijderen",
  },
  deletedialogconfirm: {
    id: "users.deletedialogconfirm",
    defaultMessage: "Verwijderen",
  },
  deletedialogcancel: {
    id: "users.deletedialogcancel",
    defaultMessage: "Annuleren",
  },
  deletedialogtext1: {
    id: "users.deletedialogtext1",
    defaultMessage: "U staat op het punt om ",
  },
  deletedialogtext2: {
    id: "users.deletedialogtext2",
    defaultMessage: "gebruiker(s) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  usergroupdialogtitle: {
    id: "users.usergroupdialogtitle",
    defaultMessage: "Gebruikers koppelen",
  },
  usergroupdialogconfirm: {
    id: "users.usergroupdialogconfirm",
    defaultMessage: "Koppelen",
  },
  usergroupdialogcancel: {
    id: "users.usergroupdialogcancel",
    defaultMessage: "Annuleren",
  },
  usergroupdialogtext1: {
    id: "users.usergroupdialogtext1",
    defaultMessage: "Selecteer aan welke gebruikersgroep u deze ",
  },
  usergroupdialogtext2: {
    id: "users.usergroupdialogtext2",
    defaultMessage: " gebruikers wenst te koppelen:",
  },
  usergroupdialogselectusergroup: {
    id: "users.usergroupdialogselectusergroup",
    defaultMessage: "Selecteer gebruikersgroep",
  },
  userdetailtitle: {
    id: "users.userdetailtitle",
    defaultMessage: "Details gebruiker",
  },
  userdetailname: {
    id: "users.userdetailname",
    defaultMessage: "Naam",
  },
  userdetailfirstname: {
    id: "users.userdetailfirstname",
    defaultMessage: "Voornaam",
  },
  userdetailemail: {
    id: "users.userdetailemail",
    defaultMessage: "E-mail",
  },
  userdetailprofile: {
    id: "users.userdetailprofile",
    defaultMessage: "Beheerder",
  },
  userdetailselectusergroups: {
    id: "users.userdetailselectusergroups",
    defaultMessage: "Selecteer gebruikersgroepen",
  },
  userdetailsave: {
    id: "users.userdetailsave",
    defaultMessage: "Wijzigingen opslaan",
  },
  userdetaildeleteownuser: {
    id: "users.userdetaildeleteownuser",
    defaultMessage: "U kan zichzelf niet verwijderen",
  },
  userdetaildelete: {
    id: "users.userdetaildelete",
    defaultMessage: "Gebruiker verwijderen",
  },
  userdetaildialogtitle: {
    id: "users.userdetaildialogtitle",
    defaultMessage: "Gebruiker verwijderen",
  },
  userdetaildialogconfirm: {
    id: "users.userdetaildialogconfirm",
    defaultMessage: "Verwijderen",
  },
  userdetaildialogcancel: {
    id: "users.userdetaildialogcancel",
    defaultMessage: "Annuleren",
  },
  userdetaildialogtext: {
    id: "users.userdetaildialogtext",
    defaultMessage: "U staat op het punt om deze gebruiker te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  userdetailmessagesuccess: {
    id: "users.userdetailmessagesuccess",
    defaultMessage: "De aanpassingen zijn opgeslagen!",
  },
  addusertitle: {
    id: "users.addusertitle",
    defaultMessage: "Nieuwe gebruiker toevoegen",
  },
  addusername: {
    id: "users.addusername",
    defaultMessage: "Naam",
  },
  adduserfirstname: {
    id: "users.adduserfirstname",
    defaultMessage: "Voornaam",
  },
  adduseremail: {
    id: "users.adduseremail",
    defaultMessage: "E-mail",
  },
  adduserorganization: {
    id: "users.adduserorganization",
    defaultMessage: "Organisatie",
  },
  adduserrole: {
    id: "users.adduserrole",
    defaultMessage: "Rol",
  },
  addusersave: {
    id: "users.addusersave",
    defaultMessage: "Gebruiker aanmaken",
  },
  addusercancel: {
    id: "users.addusercancel",
    defaultMessage: "Annuleren",
  },
  adduserusedemail: {
    id: "users.adduserusedemail",
    defaultMessage: "Een gebruiker met deze email is al gekend. Gelieve een uniek email adres te gebruiken.",
  },
  adduserlanguageLabel: {
    id: "users.languageLabel",
    defaultMessage: "Taal",
  },
  adduserFrench: {
    id: "users.adduserFrench",
    defaultMessage: "Frans",
  },
  adduserenglish: {
    id: "users.adduserenglish",
    defaultMessage: "Engels",
  },
  adduserdeutsch: {
    id: "users.adduserdeutsch",
    defaultMessage: "Duits",
  },
  adduserDutch: {
    id: "users.adduserDutch",
    defaultMessage: "Nederlands",
  },
  tooltipnotselectedtable: {
    id: "users.tooltipnotselectedtable",
    defaultMessage: "Selecteer gebruikers om te verwijderen of om toe te voegen aan gebruikersgroepen of organisatie.",
  },
  addtousergroupdialogtitle: {
    id: "users.addtousergroupdialogtitle",
    defaultMessage: "Gebruiker koppelen",
  },
  addtousergroupdialogconfirm: {
    id: "users.addtousergroupdialogconfirm",
    defaultMessage: "OK",
  },
  addtousergroupdialogcancel: {
    id: "users.addtousergroupdialogcancel",
    defaultMessage: "Annuleren",
  },
  addtoorganizationdialogtitle: {
    id: "users.addtoorganizationdialogtitle",
    defaultMessage: "Gebruiker(s) toevoegen aan organisatie",
  },
  organizationdialogselect: {
    id: "users.organizationdialogselect",
    defaultMessage: "Selecteer organisatie",
  },
  addtoorganizationdialogconfirm: {
    id: "users.addtoorganizationdialogconfirm",
    defaultMessage: "OK",
  },
  addtoorganizationdialogcancel: {
    id: "users.addtoorganizationdialogcancel",
    defaultMessage: "Annuleren",
  },
  addtousergroupdialogtext1: {
    id: "users.addtousergroupdialogtext1",
    defaultMessage: "De gebruiker",
  },
  addtousergroupdialogtext2: {
    id: "users.addtousergroupdialogtext2",
    defaultMessage: "is al lid van deze gebruikersgroep.",
  },
  organizationdialogtext1: {
    id: "users.organizationdialogtext1",
    defaultMessage: "Selecteer aan welke organisatie u",
  },
  organizationdialogtext2: {
    id: "users.organizationdialogtext2",
    defaultMessage: " gebruiker(s) wenst te toe te voegen:",
  },
});

import React, { createContext, useContext, useEffect, useState } from "react";
const NestedMenuContext = createContext({});
const createStore = ({ configuration, onClose, open }) => {
    const [openMenus, setOpenMenus] = useState([]);
    useEffect(() => {
        if (!open) {
            setOpenMenus([]);
        }
    }, [open]);
    const openMenu = (key, target) => {
        setOpenMenus((openMenus) => {
            const openMenusCopy = [...openMenus];
            openMenusCopy.push({
                key: key,
                target: target,
            });
            return openMenusCopy;
        });
    };
    /**
     * Closes a menu of a specific key. If parent is true, the parent will be closed instead of the current menu.
     * @param key
     * @param parent
     */
    const closeMenu = (key, parent = false) => {
        let index = undefined;
        if (parent) {
            const parentConfig = findParent(key, configuration);
            if (parentConfig) {
                index = openMenus.findIndex((openMenu) => openMenu.key === parentConfig.key);
            }
        }
        else {
            index = openMenus.findIndex((openMenu) => openMenu.key === key);
        }
        if (typeof index === "undefined" || index === -1) {
            return;
        }
        setOpenMenus((openMenus) => {
            const openMenusCopy = [...openMenus];
            openMenusCopy.splice(index, openMenusCopy.length - index);
            return openMenusCopy;
        });
    };
    const findOpenMenu = (key) => {
        return openMenus.find((openMenu) => openMenu.key === key);
    };
    /**
     * Find the parent configuration of a sub menu item
     * @param key
     * @param configuration
     */
    const findParent = (key, configuration) => {
        let parent = undefined;
        for (const config of configuration) {
            if (config.subMenu) {
                const isPartOf = config.subMenu.some((subMenuConfig) => subMenuConfig.key === key);
                if (isPartOf) {
                    return config;
                }
                parent = findParent(key, config.subMenu);
            }
        }
        return parent;
    };
    const closeAllMenus = (event, reason) => {
        setOpenMenus([]);
        if (typeof onClose === "function") {
            onClose(event, reason);
        }
    };
    return {
        openMenu,
        closeMenu,
        closeAllMenus,
        findOpenMenu,
        findParent,
    };
};
const NestedMenuContextProvider = (props) => {
    const store = createStore(props);
    const { children } = props;
    return React.createElement(NestedMenuContext.Provider, { value: store }, children);
};
const useNestedMenuContext = () => {
    const context = useContext(NestedMenuContext);
    if (!context) {
        throw new Error("NestedMenuContext could not be found");
    }
    return context;
};
export { NestedMenuContextProvider, useNestedMenuContext };

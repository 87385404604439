import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "usergroups.search",
    defaultMessage: "Groep zoeken...",
  },
  tableheading1: {
    id: "usergroups.tableheading1",
    defaultMessage: "Groep",
  },
  tableheading1short: {
    id: "usergroups.tableheading1short",
    defaultMessage: "Groep",
  },
  tableheading2: {
    id: "usergroups.tableheading2",
    defaultMessage: "Aantal personen",
  },
  tableheading2short: {
    id: "usergroups.tableheading2short",
    defaultMessage: "# pers",
  },
  details: {
    id: "usergroups.details",
    defaultMessage: "Details",
  },
  rowsamount: {
    id: "usergroups.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  deletedialogtitle: {
    id: "usergroups.deletedialogtitle",
    defaultMessage: "Gebruikersgroepen verwijderen",
  },
  deletedialogconfirm: {
    id: "usergroups.deletedialogconfirm",
    defaultMessage: "Verwijderen",
  },
  deletedialogcancel: {
    id: "usergroups.deletedialogcancel",
    defaultMessage: "Annuleren",
  },
  deletedialogtext1: {
    id: "usergroups.deletedialogtext1",
    defaultMessage: "U staat op het punt om ",
  },
  deletedialogtext2: {
    id: "usergroups.deletedialogtext2",
    defaultMessage: "gebruikergroep(en) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  policeappdisplayname: {
    id: "usergroupdetails.policeappdisplayname",
    defaultMessage: "Briefing",
  },
  municipalityappdisplayname: {
    id: "usergroupdetails.municipalityppdisplayname",
    defaultMessage: "Legger",
  },
  dashboardappdisplayname: {
    id: "usergroupdetails.dashboardappdisplayname",
    defaultMessage: "Dashboard",
  },
  "viewer-adminappdisplayname": {
    id: "usergroupdetails.viewer-adminappdisplayname",
    defaultMessage: "Viewer admin",
  },
  "publication-viewerappdisplayname": {
    id: "usergroupdetails.publication-viewerappdisplayname",
    defaultMessage: "Publication viewer",
  },
  unknownappdisplayname: {
    id: "usergroupdetails.unknownappdisplayname",
    defaultMessage: "Unknown app",
  },
  dailyappdisplayname: {
    id: "sidebar.dailyappdisplayname",
    defaultMessage: "Daily",
  },
  loggerappdisplayname: {
    id: "sidebar.loggerappdisplayname",
    defaultMessage: "Audit",
  },
  detailstitle: {
    id: "usergroupdetails.detailstitle",
    defaultMessage: "Details gebruikersgroep",
  },
  detailsname: {
    id: "usergroupdetails.detailsname",
    defaultMessage: "Naam",
  },
  detailsdescription: {
    id: "usergroupdetails.detailsdescription",
    defaultMessage: "Omschrijving",
  },
  detailslinkeduserstitle: {
    id: "usergroupdetails.detailslinkeduserstitle",
    defaultMessage: "Gekoppelde gebruikers",
  },
  detailslinkeduserstooltip: {
    id: "usergroupdetails.detailslinkeduserstooltip",
    defaultMessage: "Toevoegen",
  },
  detailslinkeduserstableheadername: {
    id: "usergroupdetails.detailslinkeduserstableheadername",
    defaultMessage: "Naam",
  },
  detailslinkeduserstableheaderemail: {
    id: "usergroupdetails.detailslinkeduserstableheaderemail",
    defaultMessage: "E-mail",
  },
  detailslinkeduserstabledeletetooltip: {
    id: "usergroupdetails.detailslinkeduserstabledeletetooltip",
    defaultMessage: "Ontkoppelen",
  },
  detailslinkedprofilestitle: {
    id: "usergroupdetails.detailslinkedprofilestitle",
    defaultMessage: "Gekoppelde profielen",
  },
  detailslinkedprofilestableheaderprofile: {
    id: "usergroupdetails.detailslinkedprofilestableheaderprofile",
    defaultMessage: "Profiel",
  },
  detailslinkedprofilestableheaderapp: {
    id: "usergroupdetails.detailslinkedprofilestableheaderapp",
    defaultMessage: "App",
  },
  usergroupdetaildialogtitle: {
    id: "usergroupdetails.usergroupdetaildialogtitle",
    defaultMessage: "Gebruiker verwijderen uit gebruikersgroep",
  },
  usergroupdetaildialogconfirm: {
    id: "usergroupdetails.usergroupdetaildialogconfirm",
    defaultMessage: "Verwijderen",
  },
  usergroupdetaildialogcancel: {
    id: "usergroupdetails.usergroupdetaildialogcancel",
    defaultMessage: "Annuleren",
  },
  usergroupdetaildialogtext: {
    id: "usergroupdetails.usergroupdetaildialogtext",
    defaultMessage: "U staat op het punt om deze gebruiker uit de gebruikersgroep te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
});

import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "ssogroups.search",
    defaultMessage: "SSO-profiel zoeken...",
  },
  tableheading1: {
    id: "ssogroups.tableheading1",
    defaultMessage: "Naam",
  },
  tableheading1short: {
    id: "ssogroups.tableheading1short",
    defaultMessage: "Naam",
  },
  tableheading2: {
    id: "ssogroups.tableheading2",
    defaultMessage: "Organisatie",
  },
  tableheading2short: {
    id: "ssogroups.tableheading2short",
    defaultMessage: "# org",
  },
  tableheading3: {
    id: "ssogroups.tableheading3",
    defaultMessage: "Aantal gebruikersgroepen",
  },
  tableheading3short: {
    id: "ssogroups.tableheading3short",
    defaultMessage: "# groepen",
  },
  details: {
    id: "ssogroups.details",
    defaultMessage: "Details",
  },
  detailstitle: {
    id: "ssogroups.detailstitle",
    defaultMessage: "Details SSO-profiel",
  },
  ssogroupadddialogtitle: {
    id: "ssogroups.ssogroupadddialogtitle",
    defaultMessage: "Gebruikersgroepen koppelen",
  },
  ssogroupadddialogconfirm: {
    id: "ssogroups.ssogroupadddialogconfirm",
    defaultMessage: "Koppelen",
  },
  ssogroupadddialogcancel: {
    id: "ssogroups.ssogroupadddialogcancel",
    defaultMessage: "Annuleren",
  },
  ssogroupadddialogtext: {
    id: "ssogroups.ssogroupadddialogtext1",
    defaultMessage: "Selecteer welke gebruikersgroep(en) u wenst te koppelen:",
  },
  ssogroupdetailselectusergroups: {
    id: "ssogroups.ssogroupdetailselectusergroups",
    defaultMessage: "Selecteer gebruikersgroepen",
  },
  rowsamount: {
    id: "ssogroups.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  deletedialogtitle: {
    id: "ssogroups.deletedialogtitle",
    defaultMessage: "SSO-profiel(en) verwijderen",
  },
  deletedialogconfirm: {
    id: "ssogroups.deletedialogconfirm",
    defaultMessage: "Verwijderen",
  },
  deletedialogcancel: {
    id: "ssogroups.deletedialogcancel",
    defaultMessage: "Annuleren",
  },
  deletedialogtext: {
    id: "ssogroups.deletedialogtext",
    defaultMessage: "U staat op het punt om SSO-profiel(en) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  deletedialogtext2: {
    id: "ssogroups.deletedialogtext2",
    defaultMessage: "SSO-profiel(en) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  addssogrouptitle: {
    id: "ssogroupadd.addssogrouptitle",
    defaultMessage: "Nieuwe SSO-profiel toevoegen",
  },
  addname: {
    id: "ssogroupadd.addname",
    defaultMessage: "Naam",
  },
  addorganization: {
    id: "ssogroupadd.addorganization",
    defaultMessage: "Organisatie",
  },
  addssogroupsave: {
    id: "ssogroupadd.addssogroupsave",
    defaultMessage: "SSO-profiel aanmaken",
  },
  addssogroupcancel: {
    id: "ssogroupadd.addssogroupcancel",
    defaultMessage: "Annuleren",
  },
  detailstitle: {
    id: "ssogroupdetails.detailstitle",
    defaultMessage: "Details SSO-profiel",
  },
  detailsname: {
    id: "ssogroupdetails.detailsname",
    defaultMessage: "Naam",
  },
  detailsdescription: {
    id: "ssogroupdetails.detailsdescription",
    defaultMessage: "Omschrijving",
  },
  detailslinkedusergroupstitle: {
    id: "ssogroupdetails.detailslinkedusergroupstitle",
    defaultMessage: "Gekoppelde gebruikersgroepen",
  },
  detailslinkedusergroupstooltip: {
    id: "ssogroupdetails.detailslinkedusergroupstooltip",
    defaultMessage: "Toevoegen",
  },
  detailslinkedusergroupstableheadername: {
    id: "ssogroupdetails.detailslinkedusergroupstableheadername",
    defaultMessage: "Naam",
  },
  detailslinkeduserstableheaderemail: {
    id: "ssogroupdetails.detailslinkeduserstableheaderemail",
    defaultMessage: "E-mail",
  },
  detailslinkeduserstabledeletetooltip: {
    id: "ssogroupdetails.detailslinkeduserstabledeletetooltip",
    defaultMessage: "Ontkoppelen",
  },
  detailslinkedprofilestitle: {
    id: "ssogroupdetails.detailslinkedprofilestitle",
    defaultMessage: "Gekoppelde profielen",
  },
  detailslinkedprofilestableheaderprofile: {
    id: "ssogroupdetails.detailslinkedprofilestableheaderprofile",
    defaultMessage: "Profiel",
  },
  detailslinkedprofilestableheaderapp: {
    id: "ssogroupdetails.detailslinkedprofilestableheaderapp",
    defaultMessage: "App",
  },
  ssogroupdetaildialogtitle: {
    id: "ssogroupdetails.ssogroupdetaildialogtitle",
    defaultMessage: "Gebruikersgroep verwijderen uit SSO-profiel",
  },
  ssogroupdetaildialogconfirm: {
    id: "ssogroupdetails.ssogroupdetaildialogconfirm",
    defaultMessage: "Verwijderen",
  },
  ssogroupdetaildialogcancel: {
    id: "ssogroupdetails.ssogroupdetaildialogcancel",
    defaultMessage: "Annuleren",
  },
  ssogroupdetaildialogsave: {
    id: "ssogroupdetails.ssogroupdetaildialogcancel",
    defaultMessage: "Wijzigingen opslaan",
  },
  ssogroupdetaildialogtext: {
    id: "ssogroupdetails.ssogroupdetaildialogtext",
    defaultMessage: "U staat op het punt om deze gebruikersgroep uit de SSO-profiel te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  ssogroupdetailmessagesuccess: {
    id: "ssogroupdetails.ssogroupdetailmessagesuccess",
    defaultMessage: "De aanpassingen zijn opgeslagen!",
  },
  ssogroupdetaildelete: {
    id: "ssogroupdetails.ssogroupdetaildelete",
    defaultMessage: "SSO-profiel verwijderen",
  },
});

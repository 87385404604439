import { defineMessages } from "react-intl";

export default defineMessages({
  selected: {
    id: "users.selected",
    defaultMessage: "geselecteerd",
  },
  deleteicontext: {
    id: "users.deleteicontext",
    defaultMessage: "Verwijderen",
  },
});

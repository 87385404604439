import { darken, lighten } from "@material-ui/core/styles/colorManipulator";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: "20px",
    top: "20px",
    color: theme.palette.grey[500],
  },
  buttonDanger: {
    backgroundColor: lighten(theme.palette.danger.main, 0.85),
    color: darken(theme.palette.danger.main, 0.15),
    fontWeight: "500",
  },
  buttonAlert: {
    backgroundColor: lighten(theme.palette.alert.main, 0.85),
    color: darken(theme.palette.alert.main, 0.15),
    fontWeight: "500",
  },
  buttonSuccess: {
    backgroundColor: lighten(theme.palette.success.main, 0.85),
    color: darken(theme.palette.success.main, 0.15),
    fontWeight: "500",
  },
  buttonPrimary: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    color: darken(theme.palette.primary.main, 0.15),
    fontWeight: "500",
  },
  dialogDanger: {
    border: 0,
    borderTop: "3px",
    borderColor: lighten(theme.palette.danger.main, 0.15),
    borderStyle: "solid",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  dialogPrimary: {
    border: 0,
    borderTop: "3px",
    borderColor: lighten(theme.palette.primary.main, 0.15),
    borderStyle: "solid",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  dialogSuccess: {
    border: 0,
    borderTop: "3px",
    borderColor: lighten(theme.palette.success.main, 0.15),
    borderStyle: "solid",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  dialogAlert: {
    border: 0,
    borderTop: "3px",
    borderColor: lighten(theme.palette.alert.main, 0.15),
    borderStyle: "solid",
    paddingLeft: "70px",
    paddingRight: "70px",
  },
  dialogText: {
    paddingLeft: "46px",
    paddingRight: "46px",
  },
  dialogActions: {
    margin: 0,
    padding: "10px 20px",
    backgroundColor: theme.palette.grey[100],
  },
  iconDanger: {
    color: "#fff",
    backgroundColor: lighten(theme.palette.danger.main, 0.15),
    borderRadius: "50%",
    border: "4px solid " + lighten(theme.palette.danger.main, 0.85),
    fontSize: "30px",
    lineHeight: "30px",
    position: "absolute",
    left: "24px",
    top: "24px",
  },
  iconAlert: {
    color: "#fff",
    backgroundColor: lighten(theme.palette.alert.main, 0.15),
    borderRadius: "50%",
    border: "4px solid " + lighten(theme.palette.alert.main, 0.85),
    fontSize: "30px",
    lineHeight: "30px",
    position: "absolute",
    left: "24px",
    top: "24px",
  },
  iconSuccess: {
    color: "#fff",
    backgroundColor: lighten(theme.palette.success.main, 0.15),
    borderRadius: "50%",
    border: "4px solid " + lighten(theme.palette.success.main, 0.85),
    fontSize: "30px",
    lineHeight: "30px",
    position: "absolute",
    left: "24px",
    top: "24px",
  },
  iconPrimary: {
    color: "#fff",
    backgroundColor: lighten(theme.palette.primary.main, 0.15),
    borderRadius: "50%",
    border: "4px solid " + lighten(theme.palette.primary.main, 0.85),
    fontSize: "30px",
    lineHeight: "30px",
    position: "absolute",
    left: "24px",
    top: "24px",
  },
});

export default styles;

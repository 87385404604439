import { observable, computed } from "mobx";
import { persist } from "mobx-persist";
import OrganizationModel from "./models/OrganizationModel";

export default class OrganizationStore {
  @persist("object", OrganizationModel)
  @observable
  organizationModel = new OrganizationModel();

  constructor(uiStore) {
    this.uiStore = uiStore;
  }

  @computed
  get organizationslist() {
    return this.organizationModel.organizations;
  }

  @computed
  get filteredOrganizations() {
    return this.organizationModel.filteredOrganizations;
  }
}

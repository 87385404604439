import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "municipalities.search",
    defaultMessage: "Gemeente zoeken...",
  },
  tableheading1: {
    id: "municipalities.tableheading1",
    defaultMessage: "Groep",
  },
  tableheading1short: {
    id: "municipalities.tableheading1short",
    defaultMessage: "Groep",
  },
  tableheading2: {
    id: "municipalities.tableheading2",
    defaultMessage: "Aantal personen",
  },
  tableheading2short: {
    id: "municipalities.tableheading2short",
    defaultMessage: "# pers",
  },
  details: {
    id: "municipalities.details",
    defaultMessage: "Details",
  },
  rowsamount: {
    id: "municipalities.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  deletedialogtitle: {
    id: "municipalities.deletedialogtitle",
    defaultMessage: "Gebruikersgroepen verwijderen",
  },
  deletedialogconfirm: {
    id: "municipalities.deletedialogconfirm",
    defaultMessage: "Verwijderen",
  },
  deletedialogcancel: {
    id: "municipalities.deletedialogcancel",
    defaultMessage: "Annuleren",
  },
  deletedialogtext1: {
    id: "municipalities.deletedialogtext1",
    defaultMessage: "U staat op het punt om ",
  },
  deletedialogtext2: {
    id: "municipalities.deletedialogtext2",
    defaultMessage: "gebruikergroep(en) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  municipalitydetailtitle: {
    id: "municipalities.municipalitydetailtitle",
    defaultMessage: "Details gemeente",
  },
  municipalitydetailtitleimport: {
    id: "municipalities.municipalitydetailtitleimport",
    defaultMessage: "Import gemeente data",
  },
  municipalitydetailname: {
    id: "municipalities.municipalitydetailname",
    defaultMessage: "Naam",
  },
  municipalitydetailfirstname: {
    id: "municipalities.municipalitydetailfirstname",
    defaultMessage: "Voornaam",
  },
  municipalitydetailemail: {
    id: "municipalities.municipalitydetailemail",
    defaultMessage: "E-mail",
  },
  municipalitydetailsave: {
    id: "municipalities.municipalitydetailsave",
    defaultMessage: "Wijzigingen opslaan",
  },
  municipalitydetailimport: {
    id: "municipalities.municipalitydetailimport",
    defaultMessage: "Data importeren",
  },
  municipalitydetaildelete: {
    id: "municipalities.municipalitydetaildelete",
    defaultMessage: "Gemeente verwijderen",
  },
  municipalitydetaildialogtitle: {
    id: "municipalities.municipalitydetaildialogtitle",
    defaultMessage: "Gemeente verwijderen",
  },
  municipalitydetaildialogconfirm: {
    id: "municipalities.municipalitydetaildialogconfirm",
    defaultMessage: "Verwijderen",
  },
  municipalitydetaildialogcancel: {
    id: "municipalities.municipalitydetaildialogcancel",
    defaultMessage: "Annuleren",
  },
  municipalitydetaildialogtext: {
    id: "municipalities.municipalitydetaildialogtext",
    defaultMessage: "U staat op het punt om deze gemeente te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  municipalitydetailmessagesuccess: {
    id: "municipalities.municipalitydetailmessagesuccess",
    defaultMessage: "De aanpassingen zijn opgeslagen!",
  },
  municipalityimportmessagesuccess: {
    id: "municipalities.municipalityimportmessagesuccess",
    defaultMessage: "De import is gestart!",
  },
  addmunicipalitycancel: {
    id: "users.addmunicipalitycancel",
    defaultMessage: "Annuleren",
  },
  crabimport: {
    id: "municipalities.crabimport",
    defaultMessage: "CRAB-Adressen importeren",
  },
});

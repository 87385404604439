import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Input,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { styles, datePickerTheme } from "./MunicipalityDetailsStyles";

import { ROUTE_USERS } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import messages from "./messages.js";
import { getParcelsForNis } from "services/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MunicipalityDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    userStore: { usergroups: { loadUsergroups }, users: { loadUser, activeUser, saveUser, deleteUser }, usergrouplist },
    moduleStore: {
      municipalities: { loadMunicipality, activeMunicipality, saveMunicipality, importUrbainData, deleteMunicipality },
      subscriptions: { loadSubscriptions, subscriptions },
    },
    applicationStore: { toggleSnackError },
    authStore: { user },
  } = useContext(StoresContext);

  const [dialog, setDialog] = useState(false);
  const [error, setError] = useState("");
  const [errorImport, setErrorImport] = useState("");
  const [naam, setNaam] = useState("");
  const [niscode, setNiscode] = useState("");
  const [jaar, setJaar] = useState(moment(new Date()).format("YYYY"));
  const [ftpUserName, setFtpUserName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trialStartDate, setTrialStartDate] = useState(new Date());
  const [trialEndDate, setTrialEndDate] = useState(new Date());
  const [orgcode, setOrgcode] = useState("");
  const [subscription, setSubscription] = useState("");
  const [trialSubscription, setTrialSubscription] = useState("");
  const [file, setFile] = useState(null);
  const [parcelsForNis, setParcelsForNis] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([await loadMunicipality(params.id), await loadSubscriptions()]);
      } catch (e) {
        console.log("Error::municipality details fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::municipality details fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (activeMunicipality !== null) {
      console.log(activeMunicipality);
      const newStartDate = activeMunicipality.startDate ? new Date(activeMunicipality.startDate) : null;
      const newTrialStartDate = activeMunicipality.trialStartDate ? new Date(activeMunicipality.trialStartDate) : null;
      const newEndDate = activeMunicipality.endDate ? new Date(activeMunicipality.endDate) : null;
      const newTrialEndDate = activeMunicipality.trialEndDate ? new Date(activeMunicipality.trialEndDate) : null;
      setNaam(activeMunicipality.name);
      setNiscode(activeMunicipality.niscode);
      setFtpUserName(activeMunicipality.ftpUserName);
      setStartDate(newStartDate);
      setTrialStartDate(newTrialStartDate);
      setEndDate(newEndDate);
      setTrialEndDate(newTrialEndDate);
      setOrgcode(activeMunicipality.orgcode);
      activeMunicipality.subscription ? setSubscription(activeMunicipality.subscription.id) : setSubscription("");
      activeMunicipality.trialSubscription ? setTrialSubscription(activeMunicipality.trialSubscription.id) : setTrialSubscription("");

      const fetchParcelsForNis = async () => {
        const data = await getParcelsForNis(activeMunicipality.niscode);
        setParcelsForNis(data);
      };
      fetchParcelsForNis();
    }
  }, [activeMunicipality]);

  function handleOpen() {
    return setDialog(!dialog);
  }

  const resetSub = (free) => {
    if (free) {
      setTrialStartDate(null);
      setTrialEndDate(null);
      setTrialSubscription("");
    } else {
      setStartDate(null);
      setEndDate(null);
      setSubscription("");
    }
  };

  async function submitMunicipality() {
    const newStartDate = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.sss") : null;
    const newEndDate = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.sss") : null;
    const newTrialStartDate = trialStartDate ? moment(trialStartDate).format("YYYY-MM-DD HH:mm:ss.sss") : null;
    const newTrialEndDate = trialEndDate ? moment(trialEndDate).format("YYYY-MM-DD HH:mm:ss.sss") : null;
    const municipality = {
      name: naam,
      niscode: niscode,
      orgcode: orgcode,
      ftpUserName: ftpUserName,
      startDate: newStartDate,
      endDate: newEndDate,
      subscriptionId: subscription,
      trialStartDate: newTrialStartDate,
      trialEndDate: newTrialEndDate,
      trialSubscriptionId: trialSubscription,
    };
    try {
      await saveMunicipality(activeMunicipality.id, municipality);
      setError(formatMessage(messages.municipalitydetailmessagesuccess));
    } catch (e) {
      console.log("Error::municipality details save: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::municipality details save: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitMunicipality() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  async function submitImport() {
    if (file) {
      try {
        await importUrbainData(niscode, jaar, file);
        setErrorImport(formatMessage(messages.municipalityimportmessagesuccess));
      } catch (e) {
        console.log("Error::municipality details import: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::municipality details import: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    }
  }

  function checkValidityImport() {
    let form = document.getElementById("formImport");
    form.reportValidity() ? submitImport() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  return (
    <>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h6" color="inherit">
                  {formatMessage(messages.municipalitydetailtitle)}
                </Typography>
              </Grid>
              <Grid item>
                <Button disabled={activeMunicipality !== null && activeMunicipality.id === user.id} color="secondary" className={classes.button} onClick={handleOpen}>
                  {(activeMunicipality !== null && activeMunicipality.id === user.id && formatMessage(messages.municipalitydetaildeleteownuser)) ||
                    formatMessage(messages.municipalitydetaildelete)}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <form id="form">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body2" color="inherit" gutterBottom>
                  Op deze pagina kan u de details van de gemeente aanpassen.
                  <br />
                  Onderaan is er een import voorzien voor de URBAIN data.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle2" color="inherit">
                  Algemene informatie
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="naam"
                  label={"Naam"}
                  type="text"
                  fullWidth
                  required
                  value={naam}
                  onChange={(e) => setNaam(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="niscode"
                  label={"Niscode"}
                  type="text"
                  fullWidth
                  required
                  value={niscode}
                  onChange={(e) => setNiscode(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="orgcode"
                  label={"Orgcode"}
                  type="text"
                  fullWidth
                  required
                  value={orgcode}
                  onChange={(e) => setOrgcode(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="ftpUserName"
                  label={"FTP Username"}
                  type="text"
                  fullWidth
                  value={ftpUserName}
                  onChange={(e) => setFtpUserName(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle2" color="inherit" gutterBottom>
                  Betalend abonnement
                  <Button color="secondary" variant="contained" size="small" style={{ marginLeft: 15 }} onClick={() => resetSub(false)}>
                    Opnieuw instellen
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl className={classes.abopicker} fullWidth>
                  <InputLabel id="abo-label">Abonnement</InputLabel>
                  <Select labelId="abo-label" id="abo" value={subscription} fullWidth onChange={(e) => setSubscription(e.target.value)}>
                    {subscriptions.map((subscription) => (
                      <MenuItem value={subscription.id}>{subscription.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={datePickerTheme}>
                    <DateTimePicker
                      className={classes.datepicker}
                      autoOk
                      ampm={false}
                      value={startDate}
                      onChange={setStartDate}
                      label="Start op"
                      fullWidth
                      format="dd/MM/yyyy HH:mm"
                      clearable
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={datePickerTheme}>
                    <DateTimePicker
                      className={classes.datepicker}
                      autoOk
                      ampm={false}
                      value={endDate}
                      onChange={setEndDate}
                      label="Eindigt op"
                      fullWidth
                      format="dd/MM/yyyy HH:mm"
                      clearable
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle2" color="inherit">
                  Gratis abonnement
                  <Button color="secondary" variant="contained" size="small" style={{ marginLeft: 15 }} onClick={() => resetSub(true)}>
                    Opnieuw instellen
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl className={classes.abopicker} fullWidth>
                  <InputLabel id="abo-label">Abonnement</InputLabel>
                  <Select labelId="abo2-label" id="abo2" value={trialSubscription} fullWidth onChange={(e) => setTrialSubscription(e.target.value)}>
                    {subscriptions.map((subscription) => (
                      <MenuItem value={subscription.id}>{subscription.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={datePickerTheme}>
                    <DateTimePicker
                      className={classes.datepicker}
                      autoOk
                      ampm={false}
                      value={trialStartDate}
                      onChange={setTrialStartDate}
                      label="Start op"
                      fullWidth
                      format="dd/MM/yyyy HH:mm"
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={datePickerTheme}>
                    <DateTimePicker
                      className={classes.datepicker}
                      autoOk
                      ampm={false}
                      value={trialEndDate}
                      onChange={setTrialEndDate}
                      label="Eindigt op"
                      fullWidth
                      format="dd/MM/yyyy HH:mm"
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                  {formatMessage(messages.municipalitydetailsave)}
                </Button>
              </Grid>
              <Grid item>
                <Button color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                  {formatMessage(messages.addmunicipalitycancel)}
                </Button>
              </Grid>
              <Grid item>
                <Typography>{error}</Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <CustomDialog
          open={dialog}
          setopen={handleOpen}
          title={formatMessage(messages.municipalitydetaildialogtitle)}
          type={"danger"}
          confirm={formatMessage(messages.municipalitydetaildialogconfirm)}
          confirmaction={() => {}}
          cancel={formatMessage(messages.municipalitydetaildialogcancel)}
        >
          {formatMessage(messages.municipalitydetaildialogtext)}
        </CustomDialog>
      </Paper>
      <Paper className={classes.paperImport}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h6" color="inherit">
                  {formatMessage(messages.municipalitydetailtitleimport)}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <form id="formImport">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Jaar</TableCell>
                      <TableCell align="right"># percelen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parcelsForNis.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.publication}
                        </TableCell>
                        <TableCell align="right">{row.c}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Button variant="contained" fullWidth component="label" className={classes.importBtn}>
                  Selecteer URBAIN data
                  <input type="file" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} />
                </Button>
                {file && file.name}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField id="niscode-disabled" label={"Niscode"} type="text" disabled fullWidth required value={niscode} className={classes.textField} margin="normal" />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  id="jaar"
                  label={"Jaar"}
                  type="text"
                  fullWidth
                  required
                  value={jaar}
                  onChange={(e) => setJaar(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.button} onClick={checkValidityImport}>
                  {formatMessage(messages.municipalitydetailimport)}
                </Button>
              </Grid>
              <Grid item>
                <Button color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                  {formatMessage(messages.addmunicipalitycancel)}
                </Button>
              </Grid>
              <Grid item>
                <Typography>{errorImport}</Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <CustomDialog
          open={dialog}
          setopen={handleOpen}
          title={formatMessage(messages.municipalitydetaildialogtitle)}
          type={"danger"}
          confirm={formatMessage(messages.municipalitydetaildialogconfirm)}
          confirmaction={() => {}}
          cancel={formatMessage(messages.municipalitydetaildialogcancel)}
        >
          {formatMessage(messages.municipalitydetaildialogtext)}
        </CustomDialog>
      </Paper>
    </>
  );
});

export default injectIntl(withStyles(styles)(MunicipalityDetails));

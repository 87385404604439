import React from "react";
import { Drawer, Typography, Toolbar, withStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, ExpansionPanel, ExpansionPanelSummary, FormGroup, FormControlLabel, Switch, ExpansionPanelDetails, Badge, } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./LayerSelectorStyles";
import { observer as hooksObserver } from "mobx-react";
//@ts-ignore
import { injectIntl } from "react-intl";
const LayerSelector = hooksObserver(({ classes, baseLayers, neighboorhoodLayers, thematicLayers, isOpen, onClose, title, messages, intl: { formatMessage } }) => {
    return (React.createElement(Drawer, { classes: { root: classes.root, paper: classes.drawer }, anchor: "right", open: isOpen, onClose: onClose },
        React.createElement(Toolbar, { className: classes.toolbar },
            React.createElement(Typography, { variant: "h6", className: classes.toolbar, "data-test-id": "maps-sidebar-header" }, title)),
        React.createElement("div", { className: classes.content },
            baseLayers &&
                baseLayers.layers &&
                baseLayers.layers.length > 0 &&
                baseLayers.layers.map((layer, index) => (React.createElement(List, { key: "base-" + index, "data-test-id": "basemap-" + index },
                    React.createElement(ListItem, { button: true, onClick: (event) => baseLayers.onChange(index) },
                        React.createElement(ListItemAvatar, null,
                            React.createElement(Avatar, { alt: layer.title, src: layer.avatar, className: classes.avatar })),
                        React.createElement(ListItemText, { primary: layer.title }))))),
            neighboorhoodLayers && neighboorhoodLayers.layers && (React.createElement(ExpansionPanel, null,
                React.createElement(ExpansionPanelSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "data-test-id": "expand-thematic-maps" },
                    React.createElement(Typography, { className: classes.heading },
                        neighboorhoodLayers.title,
                        React.createElement(Badge, { className: classes.badge, badgeContent: neighboorhoodLayers.layers.length, color: "primary" }, " "))),
                React.createElement(ExpansionPanelDetails, { style: { flexDirection: "column" } }, neighboorhoodLayers.layers.map((layer, index) => (React.createElement(FormGroup, { key: layer.id, "data-test-id": "thematic-map" },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { onChange: (event) => {
                                neighboorhoodLayers.onChange(layer.id, event.target.checked);
                            }, checked: layer.visible ? true : false, "data-test-id": "thematic-map-switch" }), 
                        //@ts-ignore
                        label: formatMessage(messages[layer.id.toLowerCase()]) }))))))),
            thematicLayers && thematicLayers.layers.length > 0 && (React.createElement(ExpansionPanel, null,
                React.createElement(ExpansionPanelSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                    React.createElement(Typography, { className: classes.heading },
                        thematicLayers.title,
                        React.createElement(Badge, { className: classes.badge, badgeContent: thematicLayers.layers.length, color: "primary" }, " "))),
                React.createElement(ExpansionPanelDetails, { style: { flexDirection: "column" } }, thematicLayers.layers.map((layer, index) => (React.createElement(FormGroup, { key: "thematiclayer-" + index },
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { onChange: (event) => {
                                thematicLayers.onChange(layer.id, event.target.checked);
                            }, checked: layer.visible ? true : false }), 
                        //@ts-ignore
                        label: layer.value.title }))))))))));
});
export default withStyles(styles)(injectIntl(LayerSelector));

import { defineMessages } from "react-intl";

export default defineMessages({
  search: {
    id: "organizations.search",
    defaultMessage: "Organisatie zoeken...",
  },
  tableheading1: {
    id: "organizations.tableheading1",
    defaultMessage: "Organisatiecode",
  },
  tableheading1short: {
    id: "organizations.tableheading1short",
    defaultMessage: "Orgcode",
  },
  tableheading2: {
    id: "organizations.tableheading2",
    defaultMessage: "Omschrijving",
  },
  tableheading2short: {
    id: "organizations.tableheading2short",
    defaultMessage: "# omschr",
  },
  tableheading3: {
    id: "organizations.tableheading3",
    defaultMessage: "Aantal personen",
  },
  tableheading3short: {
    id: "organizations.tableheading3short",
    defaultMessage: "# pers",
  },
  details: {
    id: "organizations.details",
    defaultMessage: "Details",
  },
  rowsamount: {
    id: "organizations.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  deletedialogtitle: {
    id: "organizations.deletedialogtitle",
    defaultMessage: "Organisaties verwijderen",
  },
  organizationdetaildelete: {
    id: "organizations.organizationdetaildelete",
    defaultMessage: "Organisatie verwijderen",
  },
  deletedialogconfirm: {
    id: "organizations.deletedialogconfirm",
    defaultMessage: "Verwijderen",
  },
  deletedialogcancel: {
    id: "organizations.deletedialogcancel",
    defaultMessage: "Annuleren",
  },
  deletedialogtext1: {
    id: "organizations.deletedialogtext1",
    defaultMessage: "U staat op het punt om ",
  },
  deletedialogtext2: {
    id: "organizations.deletedialogtext2",
    defaultMessage: "organisatie(s) te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  addorganisationtitle: {
    id: "organizationadd.addorganisationtitle",
    defaultMessage: "Nieuwe organisatie toevoegen",
  },
  addorgcode: {
    id: "organizationadd.addorgcode",
    defaultMessage: "Orgcode",
  },
  addorgdescription: {
    id: "organizationadd.addorgdescription",
    defaultMessage: "Omschrijving",
  },
  organizationmunicipalityoption: {
    id: "organization.municipality.checkbox",
    defaultMessage: "De organisatie is een gemeente",
  },
  addorganizationsave: {
    id: "organizationadd.addorganizationsave",
    defaultMessage: "Organisatie aanmaken",
  },
  addorganizationcancel: {
    id: "organizationadd.addorganizationcancel",
    defaultMessage: "Annuleren",
  },
  organizationdetailsave: {
    id: "organizationdetails.organizationdetailsave",
    defaultMessage: "Wijzigingen opslaan",
  },
  detailstitle: {
    id: "organizationdetails.detailstitle",
    defaultMessage: "Details organisatie",
  },
  detailscode: {
    id: "organizationdetails.detailsname",
    defaultMessage: "Organisatiecode",
  },
  detailsdescription: {
    id: "organizationdetails.detailsdescription",
    defaultMessage: "Omschrijving",
  },
  detailslinkeduserstitle: {
    id: "organizationdetails.detailslinkeduserstitle",
    defaultMessage: "Gekoppelde gebruikers",
  },
  detailslinkeduserstooltip: {
    id: "organizationdetails.detailslinkeduserstooltip",
    defaultMessage: "Toevoegen",
  },
  detailslinkeduserstableheadername: {
    id: "organizationdetails.detailslinkeduserstableheadername",
    defaultMessage: "Naam",
  },
  detailslinkeduserstableheaderemail: {
    id: "organizationdetails.detailslinkeduserstableheaderemail",
    defaultMessage: "E-mail",
  },
  detailslinkeduserstabledeletetooltip: {
    id: "organizationdetails.detailslinkeduserstabledeletetooltip",
    defaultMessage: "Ontkoppelen",
  },
  detailsactievessogroepentitle: {
    id: "organizationdetails.detailsactievessogroepentitle",
    defaultMessage: "Actieve SSO-profielen",
  },
  detailsssogrouptableheadername: {
    id: "organizationdetails.detailsssogrouptableheadername",
    defaultMessage: "Naam",
  },
  detailslinkedprofilestableheaderapp: {
    id: "organizationdetails.detailslinkedprofilestableheaderapp",
    defaultMessage: "App",
  },
  organizationdetaildialogtitle: {
    id: "organizationdetails.organizationdetaildialogtitle",
    defaultMessage: "Gebruiker verwijderen uit organisatie",
  },
  organizationdetaildialogconfirm: {
    id: "organizationdetails.organizationdetaildialogconfirm",
    defaultMessage: "Verwijderen",
  },
  organizationdetaildialogcancel: {
    id: "organizationdetails.organizationdetaildialogcancel",
    defaultMessage: "Annuleren",
  },
  organizationdetaildialogtext: {
    id: "organizationdetails.organizationdetaildialogtext",
    defaultMessage: "U staat op het punt om deze gebruiker uit de organisatie te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  organizationdetaildialogtext2: {
    id: "organizationdetails.organizationdetaildialogtext2",
    defaultMessage: "U staat op het punt om deze organisatie te verwijderen. Deze actie is definitief en kan niet ongedaan gemaakt worden. Wilt u doorgaan?",
  },
  organizationdetailmessagesuccess: {
    id: "organizationdetails.organizationdetailmessagesuccess",
    defaultMessage: "De aanpassingen zijn opgeslagen!",
  },
});

import { observable, action, autorun, toJS } from "mobx";
import { persist } from "mobx-persist";

import { ACTIVE_LOCALE } from "../constants";

export default class UiStore {
  @persist
  @observable
  sideBarRightOpen = false;

  @persist
  @observable
  locale = "nl";

  constructor() {
    autorun(() => {
      this.setGlobalLocale(this.locale);
    });
  }

  @action
  setGlobalLocale(locale) {
    console.log("locasetGlobalLocalele", locale, toJS(ACTIVE_LOCALE));
    ACTIVE_LOCALE.set(locale);
  }

  @action
  setLocale = (locale) => {
    this.locale = locale;
  };

  @action
  setSideBarRightOpen = (isOpen) => {
    this.sideBarRightOpen = isOpen;
  };
}

import { action, observable, computed } from "mobx";

import { getUsergroups, getUsergroup, removeUserFromUsergroup, pushUsersInUsergroup } from "../../services/api";

import Fuse from "fuse.js";
import { persist } from "mobx-persist/lib/index";
import { inject } from "mobx-react";
import { ACTIVE_LOCALE } from "../../constants";

const optionsFuzzySearch = {
  keys: ["formattedName"],
  threshold: 0.2,
};

const formatUsergroups = (usergroups) => {
  let formatUsergroups = [];
  usergroups.forEach((usergroup) => {
    if (usergroup.formattedName === undefined || usergroup.formattedName === null) {
      usergroup.formattedName = usergroup.name.toLowerCase();
    }
    formatUsergroups.push(usergroup);
  });
  return formatUsergroups;
};

const doFuzzySearch = (list, value) => {
  let filterdUsergroups = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearch);
    filterdUsergroups = fuse.search(value);
  }
  return filterdUsergroups;
};

class UsergroupModel {
  @observable searchValue = "";

  @observable _usergroups = [];

  @observable activeUsergroup = null;

  @persist
  @observable
  rowsPerPage = 5;

  @action
  setUsergroups = (value) => {
    this._usergroups = value.map((userGroup) => ({
      ...userGroup,
      name: userGroup["name_" + ACTIVE_LOCALE + "_BE"],
      description: userGroup["description_" + ACTIVE_LOCALE + "_BE"],
    }));
  };

  @action
  setActiveUsergroup = (userGroup) => {
    this.activeUsergroup = {
      ...userGroup,
      name: userGroup["name_" + ACTIVE_LOCALE + "_BE"],
      description: userGroup["description_" + ACTIVE_LOCALE + "_BE"],
      roles: userGroup.roles.map((role) => ({
        ...role,
        name: role["name_" + ACTIVE_LOCALE + "_BE"],
        description: role["description_" + ACTIVE_LOCALE + "_BE"],
      })),
    };
  };

  @computed
  get filteredusergroups() {
    return doFuzzySearch(formatUsergroups(this._usergroups), this.searchValue);
  }

  @computed
  get usergroups() {
    return this._usergroups;
  }

  @action
  loadUsergroups = async () => {
    let result = await getUsergroups();
    this.setUsergroups(result);
  };

  @action
  loadUsergroup = async (usergroupId) => {
    const result = await getUsergroup(usergroupId);
    this.setActiveUsergroup(result);
  };

  @action
  deleteUserFromUsergroup = async (usergroupId, userId) => {
    await removeUserFromUsergroup(usergroupId, userId);
    await this.loadUsergroup(usergroupId);
  };

  @action
  addUsersToUsergroup = async (userArray, usergroupId) => {
    return await pushUsersInUsergroup(
      {
        users: userArray,
      },
      usergroupId,
    );
  };

  @action
  setSearchValue = (value) => {
    this.searchValue = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };
}

export default UsergroupModel;

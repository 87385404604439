import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Paper, Grid, Button, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { withStyles } from "@material-ui/core/styles";
import styles from "./PermissionsStyles";

import { StoresContext } from "contexts";

function Permissions({ classes }) {
  const {
    userStore: { usergroups },
  } = useContext(StoresContext);

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Zoek een groep..."
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.addUser}>
                Nieuwe gebruikersgroep aanmaken
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}

export default withStyles(styles)(Permissions);

import React from "react";
import { withStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import usePopupStyles from "./PopupStyles";
import { IconButton, Box } from "@material-ui/core";
const Popup = ({ children, classes, title, onClose }) => {
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.tip }),
        React.createElement("div", { className: onClose ? classes.content : classes.contentWithoutHeader }, children),
        onClose && (React.createElement("div", { className: classes.header },
            React.createElement(Box, { display: "flex" },
                React.createElement(Box, { flexGrow: 1 }, title),
                React.createElement(Box, null,
                    React.createElement(IconButton, { onClick: onClose },
                        React.createElement(CloseIcon, { className: classes.closeIcon })),
                    " "))))));
};
export default withStyles(usePopupStyles)(Popup);

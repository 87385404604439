import React from "react";
import { Switch } from "react-router";
import { inject } from "mobx-react";
import { observer as hooksObserver } from "mobx-react-lite";

const PublicRoutes = hooksObserver(({ authStore: { isLoggedIn }, children }) => {
  if (!isLoggedIn) return <Switch>{children}</Switch>;
  return null;
});

export default inject("authStore")(PublicRoutes);

import React, { useContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, Table, TableBody, TableHead, TableRow, TableCell, TextField, Tooltip, IconButton, TablePagination } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./UsergroupDetailsStyles";

import { ROUTE_USERS } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import messages from "./messages";
import { toJS } from "mobx";

const initialState = {
  dialog: false,
  deleteUser: null,
  name: "",
  description: "",
  users: [],
  profiles: [],
  error: "",
  users_page: 0,
  users_rowsPerPage: 5,
  profiles_page: 0,
  profiles_rowsPerPage: 5,
};

function reducer(state, action) {
  switch (action.type) {
    case "dialog":
      return { ...state, dialog: action.dialog };
    case "deleteUser":
      return { ...state, deleteUser: action.deleteUser };
    case "name":
      return { ...state, name: action.name };
    case "description":
      return { ...state, description: action.description };
    case "users":
      return { ...state, users: action.users };
    case "profiles":
      return { ...state, profiles: action.profiles };
    case "error":
      return { ...state, error: action.error };
    case "users_page":
      return { ...state, users_page: action.users_page };
    case "users_rowsPerPage":
      return { ...state, users_rowsPerPage: action.users_rowsPerPage };
    case "profiles_page":
      return { ...state, profiles_page: action.profiles_page };
    case "profiles_rowsPerPage":
      return { ...state, profiles_rowsPerPage: action.profiles_rowsPerPage };
    default:
      throw new Error();
  }
}

const UsergroupDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    userStore: { usergroups: { loadUsergroup, activeUsergroup, deleteUserFromUsergroup } },
    applicationStore: { toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  function handleOpen(userid = null) {
    if (userid) {
      dispatch({ type: "deleteUser", deleteUser: userid });
    }
    dispatch({ type: "dialog", dialog: !state.dialog });
  }

  useEffect(() => {
    const fetchUsergroup = async () => {
      try {
        await loadUsergroup(params.id);
      } catch (e) {
        console.log("Error::usergroup detail fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::usergroup detail fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchUsergroup();
  }, []);

  useEffect(() => {
    if (activeUsergroup !== null) {
      dispatch({ type: "name", name: activeUsergroup.name });
      dispatch({
        type: "description",
        description: activeUsergroup.description,
      });
      dispatch({ type: "users", users: activeUsergroup.users });
      dispatch({ type: "profiles", profiles: activeUsergroup.roles });
    }
  }, [activeUsergroup]);

  async function deleteUserFromGroup(userId) {
    try {
      await deleteUserFromUsergroup(activeUsergroup.id, userId);
      handleOpen();
    } catch (e) {
      console.log("Error::usergroup detail delete user from group: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::usergroup detail delete user from group: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function handleChangePageUser(event, page) {
    dispatch({ type: "users_page", users_page: page });
  }

  function handleChangeRowsPerPageUser(event) {
    dispatch({
      type: "users_rowsPerPage",
      users_rowsPerPage: event.target.value,
    });
  }

  function handleChangePageProfile(event, page) {
    dispatch({ type: "profiles_page", profiles_page: page });
  }

  function handleChangeRowsPerPageProfile(event) {
    dispatch({
      type: "profiles_rowsPerPage",
      profiles_rowsPerPage: event.target.value,
    });
  }

  const emptyRowsUsers = state.users_rowsPerPage - Math.min(state.users_rowsPerPage, state.users.length - state.users_page * state.users_rowsPerPage);

  const emptyRowsProfiles = state.profiles_rowsPerPage - Math.min(state.profiles_rowsPerPage, state.profiles.length - state.profiles_page * state.profiles_rowsPerPage);
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6" color="inherit">
                  {formatMessage(messages.detailstitle)}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <form id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="naam"
                  label={formatMessage(messages.detailsname)}
                  type="text"
                  fullWidth
                  required
                  disabled={true}
                  value={state.name}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="description"
                  label={formatMessage(messages.detailsdescription)}
                  type="text"
                  fullWidth
                  required
                  disabled={true}
                  value={state.description}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
      <Grid container spacing={2} className={classes.doubleboxes}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="h6" color="inherit">
                      {formatMessage(messages.detailslinkeduserstitle)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={formatMessage(messages.detailslinkeduserstooltip)}>
                      <IconButton data-test-id="btn-add-user" aria-label={formatMessage(messages.detailslinkeduserstooltip)} onClick={() => push(ROUTE_USERS)}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Table className={classes.table} data-test-id="tbl-user">
              <TableHead>
                <TableRow>
                  <TableCell>{formatMessage(messages.detailslinkeduserstableheadername)}</TableCell>
                  <TableCell padding="none" colSpan={2}>
                    {formatMessage(messages.detailslinkeduserstableheaderemail)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.users.slice(state.users_page * state.users_rowsPerPage, state.users_page * state.users_rowsPerPage + state.users_rowsPerPage).map((user) => {
                  return (
                    <TableRow key={user.id}>
                      <TableCell data-test-id="user-name">{user.firstName + " " + user.lastName}</TableCell>
                      <TableCell padding="none" data-test-id="user-email">
                        {user.email}
                      </TableCell>
                      <TableCell align={"right"}>
                        <Tooltip title={formatMessage(messages.detailslinkeduserstabledeletetooltip)}>
                          <IconButton aria-label={formatMessage(messages.detailslinkeduserstabledeletetooltip)} onClick={() => handleOpen(user.id)} data-test-id="btn-delete-user">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRowsUsers > 0 && (
                  <TableRow style={{ height: 48 * emptyRowsUsers }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={state.users.length}
              rowsPerPage={state.users_rowsPerPage}
              page={state.users_page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePageUser}
              onChangeRowsPerPage={handleChangeRowsPerPageUser}
              labelRowsPerPage={"x"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="h6" color="inherit">
                      {formatMessage(messages.detailslinkedprofilestitle)}
                    </Typography>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Table data-test-id="tbl-profile">
              <TableHead>
                <TableRow>
                  <TableCell>{formatMessage(messages.detailslinkedprofilestableheaderprofile)}</TableCell>
                  <TableCell padding="none" colSpan={2}>
                    {formatMessage(messages.detailslinkedprofilestableheaderapp)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.profiles
                  .slice(state.profiles_page * state.profiles_rowsPerPage, state.profiles_page * state.profiles_rowsPerPage + state.profiles_rowsPerPage)
                  .map((profile) => {
                    return (
                      <TableRow key={profile.id}>
                        <TableCell data-test-id="profile">{profile.name}</TableCell>
                        <TableCell data-test-id="app" padding="none">
                          <FormattedMessage {...(messages[profile.app.name + "appdisplayname"] ? messages[profile.app.name + "appdisplayname"] : messages.unknownappdisplayname)} />
                        </TableCell>
                        <TableCell align={"right"}>
                          <IconButton aria-label={"Verwijderen"} onClick={null} disabled={true} data-test-id="btn-delete-profile">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRowsProfiles > 0 && (
                  <TableRow style={{ height: 48 * emptyRowsProfiles }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={state.profiles.length}
              rowsPerPage={state.profiles_rowsPerPage}
              page={state.profiles_page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePageProfile}
              onChangeRowsPerPage={handleChangeRowsPerPageProfile}
              labelRowsPerPage={"x"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
            <CustomDialog
              data-test-id="delete-popup"
              open={state.dialog}
              setopen={handleOpen}
              title={formatMessage(messages.usergroupdetaildialogtitle)}
              type={"danger"}
              confirm={formatMessage(messages.usergroupdetaildialogconfirm)}
              confirmaction={() => deleteUserFromGroup(state.deleteUser)}
              cancel={formatMessage(messages.usergroupdetaildialogcancel)}
            >
              {formatMessage(messages.usergroupdetaildialogtext)}
            </CustomDialog>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

UsergroupDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(UsergroupDetails));

import { createStyles } from "@material-ui/core/styles";
const styles = (theme) => createStyles({
    toolbar: {
        display: "flex",
        alignItems: "center",
    },
    grow: {
        flex: 1,
    },
    header: {
        display: "flex",
        alignItems: "center",
        margin: 0,
    },
});
export default styles;

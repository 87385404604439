import { lighten, darken } from "@material-ui/core/styles/colorManipulator";

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
  tableRowHeader: {
    height: "42px",
  },
  tableRow: {
    height: "60px",
  },
  tableCell: {
    padding: "4px 8px",
  },
  tableSortLabel: {
    textTransform: "uppercase",
    letterSpacing: "1.1px",
  },
  emailLink: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    display: "inline",
  },
  chipPrimary: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
    color: darken(theme.palette.primary.main, 0.15),
    fontWeight: "500",
  },
  chipDanger: {
    backgroundColor: lighten(theme.palette.danger.main, 0.85),
    color: darken(theme.palette.danger.main, 0.15),
    fontWeight: "500",
  },
  chipAlert: {
    backgroundColor: lighten(theme.palette.alert.main, 0.85),
    color: darken(theme.palette.alert.main, 0.15),
    fontWeight: "500",
  },
  chipSuccess: {
    backgroundColor: lighten(theme.palette.success.main, 0.85),
    color: darken(theme.palette.success.main, 0.15),
    fontWeight: "500",
  },
  colorPrimary: {
    color: darken(theme.palette.primary.main, 0.15),
    fontWeight: "600",
    fontSize: "30px",
  },
  colorDanger: {
    color: darken(theme.palette.danger.main, 0.15),
    fontWeight: "600",
    fontSize: "30px",
  },
  colorAlert: {
    color: darken(theme.palette.alert.main, 0.15),
    fontWeight: "600",
    fontSize: "30px",
  },
  colorSuccess: {
    color: darken(theme.palette.success.main, 0.15),
    fontWeight: "600",
    fontSize: "30px",
  },
  detailButton: {
    minWidth: 0,
  },
  wordBreak: {
    wordBreak: "break-word",
  },
});

export default styles;

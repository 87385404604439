import { SIDEBAR_CONFIG } from "constants.js";

const drawerWidth = 256;

const styles = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: "24px",
    background: "#eaeff1",
  },
  snackError: {
    backgroundColor: theme.palette.error.dark,
  },
  snackButton: {
    color: "#ffffff",
  },
  snackIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: 8,
  },
  snackMessage: {
    display: "flex",
    alignItems: "center",
  },
});

export default styles;

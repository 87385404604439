import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import UserModel from "./models/UserModel";
import UsergroupModel from "./models/UsergroupModel";

export default class UserStore {
  @persist("object", UserModel)
  @observable
  users = new UserModel();

  @persist("object", UsergroupModel)
  @observable
  usergroups = new UsergroupModel();

  constructor(uiStore) {
    this.uiStore = uiStore;
  }

  @computed
  get filteredUsers() {
    return this.users.filteredusers;
  }

  @computed
  get userlist() {
    return this.users.users;
  }

  @computed
  get filteredUsergroups() {
    return this.usergroups.filteredusergroups;
  }

  @computed
  get usergrouplist() {
    return this.usergroups.usergroups;
  }
}

import { createMuiTheme } from "@material-ui/core";
import { lighten, darken } from "@material-ui/core/styles/colorManipulator";
import lightBlue from "@material-ui/core/colors/lightBlue";

export const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  paperImport: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
    marginTop: "15px",
  },
  importBtn: {
    marginTop: "25px",
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
    "& .MuiGrid-container + .MuiGrid-container": {
      marginTop: 50,
    },
  },
  formButtons: {
    marginTop: "20px",
  },
  datepicker: {
    marginTop: 16,
    marginBottom: 8,
  },
  abopicker: {
    marginTop: 16,
    marginBottom: 8,
  },
});

export const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#009be5",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        //color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: "#009be5",
        "&:hover": {
          backgroundColor: darken("#009be5", 0.15),
        },
      },
      dayDisabled: {
        //color: lightBlue["100"],
      },
      current: {
        color: "#009be5",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#009be5",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#009be5",
      },
      thumb: {
        borderColor: "#009be5 !important",
        backgroundColor: "#009be5 !important",
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: "#009be5",
      },
      root: {
        "&:focus": {
          color: "#009be5",
        },
      },
    },
    MuiDialogActions: {
      root: {
        color: "#009be5",
        MuiButton: {
          label: {
            color: "#009be5",
          },
        },
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#009be5",
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#fff",
      },
    },
    MuiButton: {
      label: {
        color: "#009be5",
      },
    },
  },
});

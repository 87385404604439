import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, TextField } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./ProfileDetailsStyles";

import messages from "./messages.js";

const ProfileDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    applicationStore: { profiles: { loadProfile, activeProfile }, toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        loadProfile(params.id);
      } catch (e) {
        console.log("Error::profile detail fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::profile detail fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchProfile();
  }, []);

  const [naam, setNaam] = useState("");
  const [beschrijving, setBeschrijving] = useState("");

  useEffect(() => {
    if (activeProfile !== null) {
      setNaam(activeProfile.name);
      setBeschrijving(activeProfile.description);
    }
  }, [activeProfile]);

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit" data-test-id="profile-details-title">
                {formatMessage(messages.profiledetailtitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="naam"
                label={formatMessage(messages.profiledetailname)}
                data-test-id="profile-details-name"
                type="text"
                fullWidth
                required
                disabled
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="omschrijving"
                label={formatMessage(messages.profiledetaildescription)}
                data-test-id="profile-details-description"
                type="text"
                fullWidth
                required
                disabled
                value={beschrijving}
                onChange={(e) => setBeschrijving(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(ProfileDetails));

import React, { Component } from "react";
import { injectIntl } from "react-intl";
import RetryPanel from "./RetryPanel";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch = (error, errorInfo) => catchFunc(error, errorInfo, this);

  render() {
    if (this.state.errorInfo) {
      return handleError(this);
    }
    // Normally, just render children
    return this.props.children;
  }
}

export const withErrorBoundary = (WrappedComponent) =>
  injectIntl(
    class extends Component {
      constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
      }

      componentDidCatch = (error, errorInfo) => catchFunc(error, errorInfo, this);

      render() {
        if (this.state.errorInfo) {
          return handleError(this);
        }
        // Normally, just render children
        return <WrappedComponent {...this.props} />;
      }
    },
  );

const catchFunc = (error, errorInfo, ctx) => {
  // catch errors in any components below and re-render with error message
  ctx.setState({
    error: error,
    errorInfo: errorInfo,
  });
  // log error messages, etc.
};

const handleError = (ctx) => <RetryPanel />;

import axios from "axios";
import jwt_decode from "jwt-decode";

const APP_ID = process.env.REACT_APP_ID;
const API_URL = window.env.API_URL;

export const login = async (email, password) => {
  let token = null;
  let user = null;
  let {
    data: { data },
  } = await axios.post(API_URL + "login", {
    appId: APP_ID,
    email: email,
    password: password,
  });
  token = data.token;
  user = data.user;
  linkTokenToAxios(token);
  return {
    token,
    user,
  };
};

export const loginSSO = async ({ authType, sessionId }) => {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_URL}${authType === "acmidm" ? "oauth2" : "oidc"}/${authType}/login/${APP_ID}/${sessionId}`);
    const returnData = data;
    linkTokenToAxios(returnData.token);
    return returnData;
  } catch (e) {
    console.log("Error: ", e.toString());
    return false;
  }
};

export const logout = async () => {
  await axios.post(API_URL + "logout");
  linkTokenToAxios(null);
  return true;
};

export const tokenExpired = (token) => {
  let json = jwt_decode(token);
  let expTime = json.exp * 1000;
  let currentTime = Date.now();
  return currentTime >= expTime;
};

const linkTokenToAxios = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { Toolbar, Typography, Tooltip, IconButton, Button, Hidden } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import toolbarStyles from "./EnhancedTableToolbarStyles";

import DeleteIcon from "@material-ui/icons/Delete";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import messages from "./messages";

let EnhancedTableToolbar = hooksObserver(
  ({
    intl: { formatMessage },
    classes,
    numSelected,
    buttonClick,
    notSelectedTooltip = null,
    extraAction = null,
    extraIcon = null,
    extraTooltip = null,
    extraAction2 = null,
    extraIcon2 = null,
    extraTooltip2 = null,
  }) => {
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {formatMessage(messages.selected)}
            </Typography>
          ) : (
            notSelectedTooltip && (
              <Hidden smDown>
                <div className={classes.actionExtra}>
                  <SubdirectoryArrowLeftIcon className={classes.rotateArrow} />
                  <Typography variant="caption" className={classes.tooltipSelect}>
                    {notSelectedTooltip}
                  </Typography>
                </div>
              </Hidden>
            )
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions && extraAction ? classes.actionExtra : null}>
          {numSelected > 0 && extraAction && (
            <React.Fragment>
              <Hidden smDown>
                <Button data-test-id="add-user-to-usergroup-btn" color="secondary" className={classes.button} onClick={extraAction}>
                  {extraIcon}&nbsp;{extraTooltip}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Tooltip title={extraTooltip}>
                  <IconButton aria-label={extraTooltip} onClick={extraAction}>
                    {extraIcon}
                  </IconButton>
                </Tooltip>
              </Hidden>
            </React.Fragment>
          )}
          {numSelected > 0 && extraAction2 && (
            <React.Fragment>
              <Hidden smDown>
                <Button data-test-id="add-user-to-usergroup-btn" color="secondary" className={classes.button} onClick={extraAction2}>
                  {extraIcon2}&nbsp;{extraTooltip2}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <Tooltip title={extraTooltip2}>
                  <IconButton aria-label={extraTooltip2} onClick={extraAction2}>
                    {extraIcon2}
                  </IconButton>
                </Tooltip>
              </Hidden>
            </React.Fragment>
          )}
          {numSelected > 0 ? (
            <Tooltip title={formatMessage(messages.deleteicontext)}>
              <IconButton data-test-id="user-delete-icon" aria-label={formatMessage(messages.deleteicontext)} onClick={buttonClick}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </Toolbar>
    );
  },
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default injectIntl(withStyles(toolbarStyles)(EnhancedTableToolbar));

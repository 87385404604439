import { observable, computed } from "mobx";
import { persist } from "mobx-persist";
import SsoGroupModel from "./models/SsoGroupModel";

export default class SsoGroupStore {
  @persist("object", SsoGroupModel)
  @observable
  ssoGroupModel = new SsoGroupModel();

  constructor(uiStore) {
    this.uiStore = uiStore;
  }

  @computed
  get ssoGroupslist() {
    return this.ssoGroupModel.ssoGroups;
  }

  @computed
  get filteredSsoGroups() {
    return this.ssoGroupModel.filteredSsoGroups;
  }
}

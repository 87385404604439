import { defineMessages } from "react-intl";

export default defineMessages({
  tableheading1: {
    id: "profiles.tableheading1",
    defaultMessage: "Profiel",
  },
  tableheading1short: {
    id: "profiles.tableheading1short",
    defaultMessage: "Profiel",
  },
  tableheading2: {
    id: "profiles.tableheading2",
    defaultMessage: "Omschrijving",
  },
  tableheading2short: {
    id: "profiles.tableheading2short",
    defaultMessage: "Omschr.",
  },
  details: {
    id: "profiles.details",
    defaultMessage: "Details",
  },
  rowsamount: {
    id: "profiles.rowsamount",
    defaultMessage: "Aantal rijen:",
  },
  profiledetailtitle: {
    id: "profiles.profiledetailtitle",
    defaultMessage: "Details profiel",
  },
  profiledetailname: {
    id: "profiles.profiledetailname",
    defaultMessage: "Naam",
  },
  profiledetaildescription: {
    id: "profiles.profiledetaildescription",
    defaultMessage: "Omschrijving",
  },
  search: {
    id: "profiles.search",
    defaultMessage: "Profiel zoeken...",
  },
});

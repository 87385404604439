import { action, observable, computed } from "mobx";

import { getApps, getExtensions } from "../../services/api";

class ApplicationModel {
  @observable
  _applications = [];

  @observable
  _extensions = [];

  @observable
  activeapplication = null;

  @observable
  activeextension = null;

  @computed
  get applications() {
    return this._applications;
  }

  @computed
  get extensions() {
    return this._extensions;
  }

  @action
  setApplications = (value) => {
    this._applications = value;
  };

  @action
  setExtensions = (value) => {
    this._extensions = value;
  };

  @action
  setActiveApplication = (value) => {
    this.activeapplication = value;
  };

  @action
  setActiveExtension = (value) => {
    this.activeextension = value;
  };

  @action
  loadApplications = async () => {
    const result = this._applications.length > 0 ? this._applications : await getApps();
    this.setApplications(result);
  };

  @action
  loadExtensions = async () => {
    const result = await getExtensions(this.activeapplication.id);
    this.setExtensions(result);
  };
}

export default ApplicationModel;

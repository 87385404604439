import { action, observable, computed } from "mobx";
import { persist } from "mobx-persist";
import Fuse from "fuse.js/dist/fuse";

import {
  getOrganizations,
  getOrganization,
  createOrganization,
  pushOrganization,
  removeOrganization,
  removeOrganizations,
  removeUserFromOrganization,
  pushUsersInOrganization,
} from "../../services/api";

const optionsFuzzySearchOrganization = {
  keys: ["orgcode", "description"],
  threshold: 0.2,
};

const doFuzzySearchOrganizations = (list, value) => {
  let filteredProfiles = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearchOrganization);
    filteredProfiles = fuse.search(value);
  }
  return filteredProfiles;
};

class OrganizationModel {
  @observable
  _organizations = [];

  @observable
  searchValueOrganization = "";

  @observable
  activeOrganization = null;

  @persist
  @observable
  rowsPerPage = 10;

  @action
  setOrganizations = (value) => {
    this._organizations = value.map((s) => ({ ...s, organizationName: `${s.description} (${s.orgcode})` })).sort((a, b) => a.organizationName.localeCompare(b.organizationName));
  };

  @action
  setActiveOrganization = (value) => {
    this.activeOrganization = value;
  };

  @computed
  get organizations() {
    return this._organizations;
  }

  @computed
  get filteredOrganizations() {
    return doFuzzySearchOrganizations(this._organizations, this.searchValueOrganization);
  }

  @action
  loadOrganizations = async () => {
    const result = await getOrganizations();
    this.setOrganizations(result);
  };

  @action
  loadOrganization = async (orgId) => {
    const result = await getOrganization(orgId);
    this.setActiveOrganization(result);
  };

  @action
  deleteUserFromOrganization = async (userId, orgId) => {
    await removeUserFromOrganization(userId);
    await this.loadOrganization(orgId);
  };

  @action
  addOrganization = async (org) => {
    await createOrganization(org);
    await this.loadOrganizations();
  };

  @action
  saveOrganization = async (orgId, organization) => {
    await pushOrganization(orgId, organization);
    await this.loadOrganization(orgId);
  };

  @action
  deleteOrganization = async (orgId) => {
    await removeOrganization(orgId);
    this.setActiveOrganization([]);
    await this.loadOrganizations();
  };

  @action
  deleteOrganizations = async (orgArray) => {
    await removeOrganizations({ organizations: orgArray });
    await this.loadOrganizations();
  };

  @action
  setSearchValueOrganization = (value) => {
    this.searchValueOrganization = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };

  @action
  addUsersToOrganization = async (userArray, orgId) => {
    return await pushUsersInOrganization(
      {
        users: userArray,
      },
      orgId,
    );
  };
}

export default OrganizationModel;

import { createStyles } from "@material-ui/core/styles";
const usePopupStyles = (theme) => createStyles({
    splitter: {
        width: "100%",
        height: "100%",
        display: "flex",
    },
    separator: {
        cursor: "col-resize",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='30'><path d='M2 0 v30 M5 0 v30 M8 0 v30' fill='none' stroke='black'/></svg>")`,
        backgroundRepeat: " no-repeat",
        backgroundPosition: "center",
        width: "10px",
        height: " 100%",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
    },
    first: {
        width: "50%",
        minWidth: "10px",
    },
    second: {
        width: "50%",
        minWidth: "10px",
    },
});
export default usePopupStyles;

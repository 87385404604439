import React from "react";
import PropTypes from "prop-types";
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, withStyles } from "@material-ui/core";

import styles from "./CustomDialogStyles";

import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReportIcon from "@material-ui/icons/Report";

function CustomDialog(props) {
  const { classes, open, setopen, title, type, confirm, confirmaction = null, cancel = null, cancelaction = null, text = null, children = null } = props;

  function handleClose() {
    return setopen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} data-test-id="delete-popup" aria-labelledby="alert_title" aria-describedby="alert_description">
      <DialogTitle
        id="alert_title"
        className={
          (type === "danger" && classes.dialogDanger) ||
          (type === "alert" && classes.dialogAlert) ||
          (type === "success" && classes.dialogSuccess) ||
          (type === "primary" && classes.dialogPrimary)
        }
      >
        {type === "danger" && <ErrorIcon className={classes.iconDanger} />}
        {type === "alert" && <ReportIcon className={classes.iconAlert} />}
        {type === "success" && <CheckCircleIcon className={classes.iconSuccess} />}
        {type === "primary" && <InfoIcon className={classes.iconPrimary} />}
        {title}
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert_description" className={classes.dialogText}>
          {text !== null ? text : children}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container spacing={2} direction="row-reverse">
          <Grid item>
            <Button
              onClick={confirmaction !== null ? confirmaction : handleClose}
              color="primary"
              className={
                (type === "danger" && classes.buttonDanger) ||
                (type === "alert" && classes.buttonAlert) ||
                (type === "success" && classes.buttonSuccess) ||
                (type === "primary" && classes.buttonPrimary)
              }
              autoFocus
            >
              {confirm}
            </Button>
          </Grid>
          {cancel !== null && (
            <Grid item>
              <Button onClick={cancelaction !== null ? cancelaction : handleClose} color="secondary">
                {cancel}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(CustomDialog);

import React, { useContext, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { useParams } from "react-router";
import { ROUTE_LOGIN, ROUTE_SSO_SESSION_CALLBACK } from "routes/RouteList";

const SsoStartSession = hooksObserver(() => {
  const {
    authStore: { doLoginSso, ssoLogout },
  } = useContext(StoresContext);
  const [error, setError] = useState(false);
  const { authType = "" } = useParams();
  const queryParams = useQuery();
  const sessionId = queryParams.get("sessionId") || "";
  useEffect(() => {
    const loginHandler = async () => {
      try {
        const success = await doLoginSso({ sessionId, authType });
        if (success) return;
      } catch (er) {}
      ssoLogout(`${window.location.origin}${ROUTE_SSO_SESSION_CALLBACK}`);
    };

    if (sessionId && authType) {
      loginHandler();
    } else {
      setError(true);
    }
  }, []);

  const onLoginAgain = () => {
    window.location.href = ROUTE_LOGIN;
  };

  return (
    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "90vh" }}>
      {!error ? (
        <>
          <CircularProgress size={20} style={{ marginRight: 15 }} />
          <Typography>Aan het laden...</Typography>
        </>
      ) : (
        <Grid item style={{ textAlign: "center" }}>
          <Typography>Er liep iets mis bij het aanmelden, probeer het later opnieuw.</Typography>
          <br />
          <Button color="primary" onClick={onLoginAgain} variant="contained">
            Probeer opnieuw
          </Button>
        </Grid>
      )}
    </Grid>
  );
});

export default SsoStartSession;

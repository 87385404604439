import React, { useContext, useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton,
  TablePagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./SsoGroupDetailsStyles";

import { ROUTE_USERGROUPS, ROUTE_SSO_GROUPS } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import messages from "./messages";
import { toJS } from "mobx";
import { ACTIVE_LOCALE } from "../../../constants";

const initialState = {
  dialog: false,
  deleteSsoGroupdialog: false,
  addtossogroupdialog: false,
  deleteUserGroup: null,
  name: "",
  organizationName: "",
  userGroups: [],
  error: "",
  userGroups_page: 0,
  userGroups_rowsPerPage: 5,
};

function reducer(state, action) {
  switch (action.type) {
    case "dialog":
      return { ...state, dialog: action.dialog };
    case "deleteSsoGroupdialog":
      return { ...state, deleteSsoGroupdialog: action.deleteSsoGroupdialog };
    case "addtossogroupdialog":
      return { ...state, addtossogroupdialog: action.addtossogroupdialog };
    case "deleteUserGroup":
      return { ...state, deleteUserGroup: action.deleteUserGroup };
    case "name":
      return { ...state, name: action.name };
    case "userGroups":
      return { ...state, userGroups: action.userGroups };
    case "error":
      return { ...state, error: action.error };
    case "userGroups_page":
      return { ...state, userGroups_page: action.userGroups_page };
    case "userGroups_rowsPerPage":
      return { ...state, userGroups_rowsPerPage: action.userGroups_rowsPerPage };
    default:
      console.log(action.type);
      throw new Error();
  }
}

const SsoGroupDetails = hooksObserver(({ match: { params }, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    organizationStore: { organizationslist, organizationModel: { loadOrganizations } },
    ssoGroupStore: { ssoGroupModel: { loadSsoGroup, loadSsoGroups, activeSsoGroup, deleteSsoGroup, saveSsoGroup, deleteUserGroupFromSsoGroup, addUserGroupsToSsoGroup } },
    userStore: { usergroups: { loadUsergroups }, usergrouplist },
    applicationStore: { toggleSnackError },
    authStore: { forceIsLoggedInFalse },
  } = useContext(StoresContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [name, setName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [error, setError] = useState("");
  const [selectedUsergroups, setSelectedUsergroups] = useState([]);

  function handleSelectedUsergroupsChange(e) {
    setSelectedUsergroups(e.target.value);
  }

  function handleOpen(userGroupId = null) {
    if (userGroupId) {
      dispatch({ type: "deleteUserGroup", deleteUserGroup: userGroupId });
    }
    dispatch({ type: "dialog", dialog: !state.dialog });
  }
  function handleOpenDeleteSsoGroup() {
    return dispatch({ type: "deleteSsoGroupdialog", deleteSsoGroupdialog: !state.deleteSsoGroupdialog });
  }
  function handleOpenAddToSsogroup() {
    return dispatch({ type: "addtossogroupdialog", addtossogroupdialog: !state.addtossogroupdialog });
  }
  useEffect(() => {
    const fetchSsoGroup = async () => {
      try {
        await Promise.all([loadSsoGroup(params.id), loadUsergroups(), loadOrganizations()]);
      } catch (e) {
        console.log("Error::ssogroup detail fetch: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::ssogroup detail fetch: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    fetchSsoGroup();
  }, []);
  useEffect(() => {
    if (activeSsoGroup !== null) {
      dispatch({ type: "name", name: activeSsoGroup.name });
      dispatch({ type: "userGroups", userGroups: activeSsoGroup.userGroups });
    }
  }, [activeSsoGroup]);

  async function deleteUserGroupFromSsoProfile() {
    try {
      await deleteUserGroupFromSsoGroup(activeSsoGroup.id, state.deleteUserGroup);
      handleOpen();
    } catch (e) {
      console.log("Error::ssogroup detail delete usergroup: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::ssogroup detail delete usergroup: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  async function addUserGrouptoSsoProfile() {
    try {
      await addUserGroupsToSsoGroup(selectedUsergroups, activeSsoGroup.id);
      handleOpenAddToSsogroup();
    } catch (e) {
      console.log("Error::ssogroup detail add usergroup(s): ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::ssogroup detail aa usergroup(s): We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  function handleChangePageUserGroup(event, page) {
    dispatch({ type: "userGroups_page", userGroups_page: page });
  }

  function handleChangeRowsPerPageUserGroup(event) {
    dispatch({
      type: "userGroups_rowsPerPage",
      userGroups_rowsPerPage: event.target.value,
    });
  }

  const emptyRowsUserGroups =
    state.userGroups_rowsPerPage - Math.min(state.userGroups_rowsPerPage, state.userGroups?.length - state.userGroups_page * state.userGroups_rowsPerPage);

  async function deleteThisSsoGroup() {
    try {
      await deleteSsoGroup(activeSsoGroup.id);
      handleOpenDeleteSsoGroup();
      await loadSsoGroups();
      push(ROUTE_SSO_GROUPS);
    } catch (e) {
      console.log("Error::ssoGroup details delete: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::ssoGroup details delete: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  useEffect(() => {
    if (activeSsoGroup !== null) {
      setName(activeSsoGroup.name);
      setOrganizationId(activeSsoGroup.organizationId || "null");
    }
  }, [activeSsoGroup]);

  async function submitSsoGroup() {
    let ssoGroup = {
      name: name,
      organizationId: organizationId === "null" ? null : organizationId,
    };
    try {
      await saveSsoGroup(activeSsoGroup.id, ssoGroup);
      setError(formatMessage(messages.ssogroupdetailmessagesuccess));
    } catch (e) {
      console.log("Error::ssoGroup details save: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::ssoGroup details save: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }
  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitSsoGroup() : null;
  }
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography variant="h6" color="inherit">
                  {formatMessage(messages.detailstitle)}
                </Typography>
              </Grid>
              <Grid item>
                <Button data-test-id="delete-ssogroup" color="secondary" className={classes.button} onClick={handleOpenDeleteSsoGroup}>
                  {formatMessage(messages.ssogroupdetaildelete)}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <form id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  id="name"
                  label="Naam"
                  type="text"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={classes.textField}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} fullWidth margin="normal">
                  <InputLabel id="language-select-label">Organisatie</InputLabel>
                  <Select
                    value={organizationId}
                    fullWidth
                    labelId="-select-label"
                    id="organization-select"
                    onChange={(event) => {
                      setOrganizationId(event.target.value);
                    }}
                  >
                    <MenuItem key={"no-org"} value={"null"}>
                      *
                    </MenuItem>
                    {organizationslist.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        {org.organizationName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                  {formatMessage(messages.ssogroupdetaildialogsave)}
                </Button>
              </Grid>
              <Grid item>
                <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_USERS)}>
                  {formatMessage(messages.ssogroupdetaildialogcancel)}
                </Button>
              </Grid>
              <Grid item>
                <Typography>{error}</Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </Paper>
      <Grid container spacing={2} className={classes.doubleboxes}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <Typography variant="h6" color="inherit">
                      {formatMessage(messages.detailslinkedusergroupstitle)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title={formatMessage(messages.detailslinkedusergroupstooltip)}>
                      <IconButton data-test-id="btn-add-usergroup" aria-label={formatMessage(messages.detailslinkedusergroupstooltip)} onClick={handleOpenAddToSsogroup}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Table className={classes.table} data-test-id="tbl-usergroup">
              <TableHead>
                <TableRow>
                  <TableCell>{formatMessage(messages.detailslinkedusergroupstableheadername)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.userGroups
                  ?.slice(state.userGroups_page * state.userGroups_rowsPerPage, state.userGroups_page * state.userGroups_rowsPerPage + state.userGroups_rowsPerPage)
                  .map((userGroup) => {
                    return (
                      <TableRow key={userGroup.id}>
                        <TableCell data-test-id="userGroup-name">{userGroup[`name_${ACTIVE_LOCALE}_BE`]}</TableCell>
                        <TableCell align={"right"}>
                          <Tooltip title={formatMessage(messages.detailslinkeduserstabledeletetooltip)}>
                            <IconButton
                              aria-label={formatMessage(messages.detailslinkeduserstabledeletetooltip)}
                              onClick={() => handleOpen(userGroup.id)}
                              data-test-id="btn-delete-usergroup"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRowsUserGroups > 0 && (
                  <TableRow style={{ height: 48 * emptyRowsUserGroups }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={state.userGroups?.length}
              rowsPerPage={state.userGroups_rowsPerPage}
              page={state.userGroups_page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePageUserGroup}
              onChangeRowsPerPage={handleChangeRowsPerPageUserGroup}
              labelRowsPerPage={"x"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            />
            <CustomDialog
              data-test-id="delete-popup"
              open={state.dialog}
              setopen={handleOpen}
              title={formatMessage(messages.ssogroupdetaildialogtitle)}
              type={"danger"}
              confirm={formatMessage(messages.ssogroupdetaildialogconfirm)}
              confirmaction={deleteUserGroupFromSsoProfile}
              cancel={formatMessage(messages.ssogroupdetaildialogcancel)}
            >
              {formatMessage(messages.ssogroupdetaildialogtext)}
            </CustomDialog>
            <CustomDialog
              data-test-id="deletessogroup-popup"
              open={state.deleteSsoGroupdialog}
              setopen={handleOpenDeleteSsoGroup}
              title={formatMessage(messages.deletedialogtitle)}
              type={"danger"}
              confirm={formatMessage(messages.deletedialogconfirm)}
              confirmaction={deleteThisSsoGroup}
              cancel={formatMessage(messages.ssogroupdetaildialogcancel)}
            >
              {formatMessage(messages.deletedialogtext)}
            </CustomDialog>
            <CustomDialog
              open={state.addtossogroupdialog}
              setopen={handleOpenAddToSsogroup}
              title={formatMessage(messages.ssogroupadddialogtitle)}
              type={"primary"}
              confirm={formatMessage(messages.ssogroupadddialogconfirm)}
              confirmaction={addUserGrouptoSsoProfile}
              cancel={formatMessage(messages.ssogroupadddialogcancel)}
            >
              {formatMessage(messages.ssogroupadddialogtext)}
              <br />
              <br />
              <form>
                <FormControl className={classes.formControl} fullWidth margin="normal">
                  <InputLabel htmlFor="select-multiple-checkbox">{formatMessage(messages.ssogroupdetailselectusergroups)}</InputLabel>
                  <Select
                    inputProps={{
                      "data-test-id": "usergroup",
                    }}
                    data-test-id="tf-usergroup"
                    multiple
                    value={selectedUsergroups}
                    onChange={handleSelectedUsergroupsChange}
                    input={<Input id="select-multiple-checkbox" />}
                    fullWidth
                    renderValue={(selected) => {
                      let names = [];
                      usergrouplist.map((usergrp) => {
                        if (selected.indexOf(usergrp.id) > -1) {
                          names.push(usergrp.name);
                        }
                      });
                      const text = names.join(", ");
                      return text;
                    }}
                  >
                    {activeSsoGroup &&
                      usergrouplist
                        .filter((usergroup) => !activeSsoGroup.userGroups.some((activeUserGroup) => activeUserGroup.id === usergroup.id))
                        .map((usergroup) => (
                          <MenuItem key={usergroup.id} value={usergroup.id} data-test-id={usergroup.name}>
                            <Checkbox checked={selectedUsergroups.indexOf(usergroup.id) > -1} />
                            <ListItemText primary={usergroup.name} />
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </form>
            </CustomDialog>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

SsoGroupDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(SsoGroupDetails));

import { observable, action, computed } from "mobx";
import { persist } from "mobx-persist";
import MunicipalityModel from "./models/MunicipalityModel";
import SubscriptionModel from "./models/SubscriptionModel";

export default class ModuleStore {
  @persist("object", MunicipalityModel)
  @observable
  municipalities = new MunicipalityModel();

  @persist("object", SubscriptionModel)
  @observable
  subscriptions = new SubscriptionModel();

  @computed
  get filteredMunicipalities() {
    return this.municipalities.filteredmunicipalities;
  }
}

import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef } from "react";
import useHorizontalSplitterStyles from "./HorizontalSplitterStyles";
const HorizontalSplitter = ({ classes, contentLeft, contentRight }) => {
    const separatorRef = useRef();
    const leftContentRef = useRef();
    const rightContentRef = useRef();
    useEffect(() => {
        dragElement(separatorRef.current, "H");
    }, [separatorRef]);
    const dragElement = (element, direction) => {
        var md; // remember mouse down info
        const first = leftContentRef.current;
        const second = rightContentRef.current;
        if (!(first && second))
            return;
        element.onmousedown = onMouseDown;
        function onMouseDown(e) {
            md = {
                e,
                offsetLeft: element.offsetLeft,
                offsetTop: element.offsetTop,
                //@ts-ignore
                firstWidth: first.offsetWidth,
                //@ts-ignore
                secondWidth: second.offsetWidth,
            };
            document.onmousemove = onMouseMove;
            document.onmouseup = () => {
                document.onmousemove = document.onmouseup = null;
            };
        }
        const onMouseMove = (e) => {
            var delta = { x: e.clientX - md.e.clientX, y: e.clientY - md.e.clientY };
            if (direction === "H") {
                // Horizontal
                // Prevent negative-sized elements
                delta.x = Math.min(Math.max(delta.x, -md.firstWidth), md.secondWidth);
                element.style.left = md.offsetLeft + delta.x + "px";
                //@ts-ignore
                first.style.width = md.firstWidth + delta.x + "px";
                //@ts-ignore
                second.style.width = md.secondWidth - delta.x + "px";
            }
        };
    };
    return (React.createElement("div", { className: classes.splitter },
        React.createElement("div", { className: classes.first, ref: leftContentRef }, contentLeft),
        React.createElement("div", { className: classes.separator, ref: separatorRef }),
        React.createElement("div", { className: classes.second, ref: rightContentRef }, contentRight)));
};
export default withStyles(useHorizontalSplitterStyles)(HorizontalSplitter);

import React, { useContext } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { Login } from "@orbit/components";
import { DEFAULT_LOCALE } from "../../constants";

const LoginView = ({ intl: { formatMessage } }) => {
  const {
    authStore: { doLogin, startSSO, authStrategies },
  } = useContext(StoresContext);

  const { CLIENT_PORTAL_URL } = window.env;

  return (
    <Login
      messages={{
        appTitle: formatMessage(messages.appName),
        loginTitle: formatMessage(messages.loginDescription),
        loginSSOTitle: formatMessage(messages.loginDescriptionSso),
        loginSSOSeperator: formatMessage(messages.loginDescriptionOptionalSeperator),
        title: formatMessage(messages.title),
        labelEmail: formatMessage(messages.labelEmail),
        labelEmailHelper: formatMessage(messages.labelEmailHelper),
        labelPassword: formatMessage(messages.labelPassword),
        button: formatMessage(messages.button),
        forgotPassword: formatMessage(messages.forgotPassword),
      }}
      doLogin={doLogin}
      urlForgotPassword={`${CLIENT_PORTAL_URL}${DEFAULT_LOCALE}/forgotpassword`}
      doLoginSSO={(type) => startSSO(type)}
      authStrategies={authStrategies}
    />
  );
};

export default injectIntl(LoginView);

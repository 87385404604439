export function tableReducer(state, action) {
  switch (action.type) {
    case "order":
      return { ...state, order: action.order };
    case "orderBy":
      return { ...state, orderBy: action.orderBy };
    case "selected":
      return { ...state, selected: action.selected };
    case "page":
      return { ...state, page: action.page };
    case "dialog":
      return { ...state, dialog: !state.dialog };
    case "searchValue":
      return { ...state, searchValue: action.searchValue };
    default:
      throw new Error();
  }
}

export function tableStableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styles from "./HorizontalDataTableStyles";
import { withStyles } from "@material-ui/styles";
//@ts-ignore
import MUIDataTable from "mui-datatables";
const HorizontalDataTable = ({ title = "", columns = [], data = [], options = {}, muiTheme = styles }) => {
    return (React.createElement(MuiThemeProvider, { theme: muiTheme },
        React.createElement(MUIDataTable, { title: title, data: data, columns: columns, options: options })));
};
export default withStyles(styles)(HorizontalDataTable);

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { Fab, Menu, MenuItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./HeaderFabStyles";

import { StoresContext } from "contexts";

const HeaderFab = hooksObserver(({ fabtitle, fabicon, fabaction = null, fabactions = [], fabmenuid = null, intl: { formatMessage }, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Fab
        aria-owns={fabaction === null && open ? fabmenuid : undefined}
        aria-haspopup={fabaction === null && "true"}
        onClick={(fabaction === null && handleMenu) || fabaction}
        variant="extended"
        color="inherit"
        className={classes.customfab}
      >
        {fabtitle}
        {fabicon}
      </Fab>
      {fabaction === null && (
        <Menu
          id={fabmenuid}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {fabactions.map(({ title, funct, testId = "li-logout" }) => {
            return (
              <MenuItem
                data-test-id={testId}
                key={Math.random()}
                onClick={() => {
                  funct();
                  handleClose();
                }}
              >
                {title}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
});

export default injectIntl(withStyles(styles)(HeaderFab));

import React, { useState, Fragment } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Fab, Menu, MenuItem, withStyles } from "@material-ui/core";
import styles from "./HeaderFabStyles";
const HeaderFab = ({ classes, fabtitle, fabicon, id = null, fabaction = null, fabactions = [], fabmenuid = undefined, width }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        //@ts-ignore
        setAnchorEl(event.currentTarget);
    };
    function handleClose() {
        setAnchorEl(null);
    }
    return (React.createElement(Fragment, null,
        React.createElement(Fab, { id: id ? id : undefined, "aria-owns": fabaction === null && open ? fabmenuid : undefined, "aria-haspopup": fabaction === null, 
            //@ts-ignore
            onClick: (fabaction === null && handleMenu) || fabaction, variant: "extended", color: "inherit", className: classes.customfab },
            isWidthUp("sm", width) && fabtitle,
            fabicon),
        fabaction === null && (React.createElement(Menu, { id: fabmenuid, anchorEl: anchorEl, anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, open: open, onClose: handleClose }, fabactions.map((action, index) => (React.createElement(MenuItem, { key: "menu-action-" + fabtitle + index, onClick: (e) => {
                action.funct();
                handleClose();
            } }, action.title)))))));
};
export default withStyles(styles)(withWidth()(HeaderFab));

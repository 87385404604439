import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import { AppBar, Toolbar, Typography, Paper, Grid, Button, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import styles from "./AddSsoGroupStyles";

import { ROUTE_SSO_GROUPS } from "../../../routes/RouteList";

import messages from "./messages.js";

const AddSsoGroup = hooksObserver(({ intl: { formatMessage }, classes }) => {
  const {
    ssoGroupStore: { ssoGroupModel: { addSsoGroup } },
    organizationStore: { organizationslist, organizationModel: { loadOrganizations } },
    uiStore: { locale },
    authStore: { forceIsLoggedInFalse },
    routingStore: { push },
    applicationStore: { toggleSnackError },
  } = useContext(StoresContext);

  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [organizationId, setOrganizationId] = useState("null");

  useEffect(() => {
    const loadAllData = async () => {
      try {
        await Promise.all([loadOrganizations()]);
      } catch (e) {
        console.log("Error::fetch data for form: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::fetch data for form: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    };
    loadAllData();
  }, []);

  async function submitSsoGroup() {
    let ssoGroup = {
      name,
      organizationId: organizationId === "null" ? null : organizationId,
    };
    try {
      await addSsoGroup(ssoGroup);
      setError("");
      push(ROUTE_SSO_GROUPS);
    } catch (e) {
      console.log("Error::add ssoGroup: ", e.toString());
      switch (e.response.status) {
        case 401:
          forceIsLoggedInFalse();
          break;
        default:
          toggleSnackError();
          console.log("TODO::add ssoGroup: We still need to catch the following error: ", e.response.status);
          break;
      }
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitSsoGroup() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.addssogrouptitle)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="name"
                inputProps={{
                  "data-test-id": "name",
                }}
                label={formatMessage(messages.addname)}
                type="text"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl className={classes.formControl} fullWidth margin="normal">
                <InputLabel id="language-select-label">{formatMessage(messages.addorganization)}</InputLabel>
                <Select
                  value={organizationId}
                  fullWidth
                  labelId="-select-label"
                  id="organization-select"
                  onChange={(event) => {
                    setOrganizationId(event.target.value);
                  }}
                >
                  <MenuItem key={"no-org"} value={"null"}>
                    *
                  </MenuItem>
                  {organizationslist.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.organizationName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button data-test-id="submit-button" variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                {formatMessage(messages.addssogroupsave)}
              </Button>
            </Grid>
            <Grid item>
              <Button data-test-id="cancel-button" color="secondary" className={classes.button} onClick={() => push(ROUTE_SSO_GROUPS)}>
                {formatMessage(messages.addssogroupcancel)}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(AddSsoGroup));

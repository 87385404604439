import { createStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
const usePopupStyles = (theme) => createStyles({
    root: {
        maxWidth: "350px",
        //	minWidth: "100px",
        display: "flex",
        position: "absolute",
        flexDirection: "column-reverse",
        transform: "translate(-50%, -100%)",
        zIndex: 12000,
    },
    tip: {
        width: 0,
        height: 0,
        border: "10px solid transparent",
        zIndex: 1,
        alignSelf: "center",
        borderBottom: "none",
        borderTopColor: "#fff",
    },
    header: {
        width: "100%",
        background: "#fff",
    },
    content: {
        overflow: "auto",
        position: "relative",
        background: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 2px rgba(0,0,0,.1)",
        padding: "0px 10px 15px",
        pointerEvents: "auto",
        zIndex: 32000,
    },
    contentWithoutHeader: {
        overflow: "auto",
        position: "relative",
        background: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 2px rgba(0,0,0,.1)",
        padding: "10px 10px 15px",
        pointerEvents: "auto",
        zIndex: 32000,
    },
    closeIcon: {
        padding: 4,
        color: grey[400],
        "&:hover": {
            borderRadius: "24px",
        },
    },
});
export default usePopupStyles;

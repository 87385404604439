import { action, observable, computed } from "mobx";
import { persist } from "mobx-persist";

import { getUsers, getUser, createUser, pushUser, removeUser, removeUsers } from "../../services/api";

import Fuse from "fuse.js";

const optionsFuzzySearch = {
  keys: ["volledigenaam", "email", "type"],
  threshold: 0.2,
};

const formatUsers = (users) => {
  let formatUsers = [];
  users.forEach((user) => {
    if (user.volledigenaam === undefined || user.volledigenaam === null) {
      // check nodig zodat we niet onnodig de volledigenaam zetten
      user.volledigenaam = (user.firstName + " " + user.lastName).toLowerCase();
    }
    formatUsers.push(user);
  });
  return formatUsers;
};

const doFuzzySearch = (list, value) => {
  let filterdUsers = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearch);
    filterdUsers = fuse.search(value);
  }
  return filterdUsers;
};

class UserModel {
  @observable
  searchValue = "";

  @observable
  _users = [];

  @observable
  activeUser = null;

  @persist
  @observable
  rowsPerPage = 10;

  @action
  setUsers = (value) => {
    this._users = value.map((u) => ({ ...u, hasOrganization: !!u.organizationId }));
  };

  @action
  setActiveUser = (value) => {
    this.activeUser = value;
  };

  @computed
  get filteredusers() {
    return doFuzzySearch(formatUsers(this._users), this.searchValue);
  }

  @computed
  get users() {
    return this._users;
  }

  @action
  loadUsers = async () => {
    const result = await getUsers();
    this.setUsers(result);
  };

  @action
  loadUser = async (userId, loadUsergroups = false) => {
    const result = await getUser(userId, loadUsergroups);
    this.setActiveUser(result);
  };

  @action
  addUser = async (user) => {
    await createUser(user);
    await this.loadUsers();
  };

  @action
  saveUser = async (userId, user) => {
    await pushUser(userId, user);
    await this.loadUser(userId);
  };

  @action
  deleteUser = async (userId) => {
    await removeUser(userId);
    this.setActiveUser([]);
    await this.loadUsers();
  };

  @action
  deleteUsers = async (userArray) => {
    await removeUsers({ users: userArray });
    await this.loadUsers();
  };

  @action
  setSearchValue = (value) => {
    this.searchValue = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };
}

export default UserModel;

import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, Paper, Grid, Button, TextField } from "@material-ui/core";

import { StoresContext } from "contexts";

import { withStyles } from "@material-ui/core/styles";
import styles from "./AddUsergroupStyles";

import { ROUTE_USERGROUPS } from "../../../routes/RouteList";

function AddUsergroup({ classes }) {
  const {
    userStore: { usergroups, addUsergroup },
    routingStore: { push },
  } = useContext(StoresContext);

  const [error, setError] = useState("");
  const [naam, setNaam] = useState("");

  function submitUsergroup() {
    const group = {
      id: usergroups.length + 1,
      name: naam,
      members: [],
    };
    if (addUsergroup(group)) {
      setError("");
      push(ROUTE_USERGROUPS);
    } else {
      setError("Deze gebruikersgroep bestaat al. Gelieve een andere naam in te vullen.");
      document.getElementById("naam").blur();
    }
  }

  function checkValidity() {
    let form = document.getElementById("form");
    form.reportValidity() ? submitUsergroup() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                Nieuwe gebruikersgroep toevoegen
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                id="naam"
                label="Naam"
                type="text"
                fullWidth
                required
                value={naam}
                onChange={(e) => setNaam(e.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.button} onClick={checkValidity}>
                Gebruikersgroep aanmaken
              </Button>
            </Grid>
            <Grid item>
              <Button color="secondary" className={classes.button} onClick={() => push(ROUTE_USERGROUPS)}>
                Annuleren
              </Button>
            </Grid>
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(AddUsergroup);

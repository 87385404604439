import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import messages from "./messages";

const RetryPanel = () => (
  <div>
    <div className={classNames("c-panel--empty-state-wrapper")}>
      <div className="c-panel--mesage-icon">there is an console.error;</div>
    </div>
  </div>
);

export default RetryPanel;

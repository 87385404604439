import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell, Checkbox, Hidden, TableSortLabel } from "@material-ui/core";

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows, classes, isCheckboxTable = true } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableRowHeader} selected={true}>
          {isCheckboxTable && (
            <TableCell padding="checkbox">
              <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount} checked={numSelected === rowCount} onChange={onSelectAllClick} />
            </TableCell>
          )}
          {rows.map(
            (row) =>
              row.hide ? (
                <Hidden key={row.id} xsDown>
                  <TableCell
                    className={row.disablePadding === "default" ? "empty" : row.disablePadding ? "empty" : classes.tableCell}
                    align={row.numeric ? "right" : "left"}
                    padding={row.disablePadding === "default" ? "default" : row.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <TableSortLabel className={classes.tableSortLabel} active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                      <Hidden xsDown>{row.label}</Hidden>
                      <Hidden smUp>{row.mobileLabel}</Hidden>
                    </TableSortLabel>
                  </TableCell>
                </Hidden>
              ) : (
                <TableCell
                  className={row.disablePadding === "default" ? "empty" : row.disablePadding ? "empty" : classes.tableCell}
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding === "default" ? "default" : row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <TableSortLabel className={classes.tableSortLabel} active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                    <Hidden xsDown>{row.label}</Hidden>
                    <Hidden smUp>{row.mobileLabel}</Hidden>
                  </TableSortLabel>
                </TableCell>
              ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
};

export default EnhancedTableHead;

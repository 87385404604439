import { action, observable, computed, toJS } from "mobx";
import { persist } from "mobx-persist";
import { getSubscriptions } from "services/api";

class SubscriptionModel {
  @observable
  _subscriptions = [];

  @action
  setSubscriptions = (value) => {
    this._subscriptions = value;
  };

  @computed
  get subscriptions() {
    return this._subscriptions;
  }

  @action
  loadSubscriptions = async () => {
    const result = await getSubscriptions();
    this.setSubscriptions(result);
  };
}

export default SubscriptionModel;

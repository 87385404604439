import { action, observable, computed } from "mobx";
import { persist } from "mobx-persist";
import Fuse from "fuse.js/dist/fuse";

import {
  getSsoGroups,
  getSsoGroup,
  createSsoGroup,
  pushSsoGroup,
  removeSsoGroup,
  removeSsoGroups,
  removeUserGroupFromSsogroup,
  pushUserGroupsInSsogroup,
} from "../../services/api";

const optionsFuzzySearchSsoGroup = {
  keys: ["name", "organizationName"],
  threshold: 0.2,
};

const doFuzzySearchSsoGroups = (list, value) => {
  let filteredSsoGroups = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearchSsoGroup);
    filteredSsoGroups = fuse.search(value);
  }
  return filteredSsoGroups;
};

class SsoGroupModel {
  @observable
  _ssoGroups = [];

  @observable
  searchValueSsoGroup = "";

  @observable
  activeSsoGroup = null;

  @persist
  @observable
  rowsPerPage = 10;

  @action
  setSsoGroups = (value) => {
    this._ssoGroups = value.map((s) => ({ ...s, organizationName: s.organization ? `${s.organization.description} (${s.organization.orgcode})` : "*" }));
  };

  @action
  setActiveSsoGroup = (value) => {
    this.activeSsoGroup = value;
  };

  @computed
  get ssoGroups() {
    return this._ssoGroups;
  }

  @computed
  get filteredSsoGroups() {
    return doFuzzySearchSsoGroups(this._ssoGroups, this.searchValueSsoGroup);
  }

  @action
  loadSsoGroups = async () => {
    const result = await getSsoGroups();
    this.setSsoGroups(result);
  };

  @action
  loadSsoGroup = async (ssoGroupId) => {
    const result = await getSsoGroup(ssoGroupId);
    this.setActiveSsoGroup(result);
  };

  @action
  addSsoGroup = async (ssoGroup) => {
    await createSsoGroup(ssoGroup);
    await this.loadSsoGroups();
  };

  @action
  saveSsoGroup = async (ssoGroupId, ssoGroup) => {
    await pushSsoGroup(ssoGroupId, ssoGroup);
    await this.loadSsoGroup(ssoGroupId);
  };

  @action
  deleteSsoGroup = async (ssoGroupId) => {
    await removeSsoGroup(ssoGroupId);
    this.setActiveSsoGroup([]);
    await this.loadSsoGroups();
  };
  @action
  deleteUserGroupFromSsoGroup = async (ssoGroupId, userGroupId) => {
    await removeUserGroupFromSsogroup(ssoGroupId, userGroupId);
    await this.loadSsoGroup(ssoGroupId);
  };
  @action
  addUserGroupsToSsoGroup = async (userGroupArray, ssogroupId) => {
    await pushUserGroupsInSsogroup(
      {
        userGroups: userGroupArray,
      },
      ssogroupId,
    );
    await this.loadSsoGroup(ssogroupId);
  };

  @action
  deleteSsoGroups = async (ssoArray) => {
    await removeSsoGroups({ ssoGroups: ssoArray });
    await this.loadSsoGroups();
  };

  @action
  setSearchValueSsoGroup = (value) => {
    this.searchValueSsoGroup = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };
}

export default SsoGroupModel;

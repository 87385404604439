const width = "315px";
import { createStyles } from "@material-ui/core/styles";
const useLayerSelectorStyles = (theme) => createStyles({
    root: {
        overflowY: "auto",
    },
    fab: {
        margin: theme.spacing(1),
        color: "#e6e6e6",
    },
    toolbar: {
        color: "white",
        backgroundColor: theme.palette.secondary.main,
    },
    drawer: {
        overflowY: "initial",
    },
    content: {
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        minWidth: width,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        //fontWeight: theme.typography.fontWeightRegular
    },
    badge: {
        marginLeft: "16px",
    },
    pos: {
        marginBottom: 12,
    },
    postop: {
        marginTop: 12,
        marginBottom: 12,
    },
});
export default useLayerSelectorStyles;

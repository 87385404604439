import { action, computed, observable } from "mobx";
import { persist } from "mobx-persist";
import { ROUTE_LOGIN, ROUTE_SSO_SESSION_CALLBACK } from "routes/RouteList";
import { login, loginSSO, logout } from "../services/auth";

const AUTH_STRATEGIES_CONFIG = [
  { type: "user-pass", label: "Login", isSSO: false },
  { type: "keycloak", label: "Keycloak", isSSO: true },
  { type: "acmidm", label: "ACM/IDM", isSSO: true },
  { type: "police", label: "SSO", isSSO: true },
];

const { API_URL, AUTH_STRATEGIES = "user-pass" } = window.env;

export default class AdminAuthStore {
  @persist
  @observable
  isLoggedIn = false;

  @persist("object")
  @observable
  user = {};

  @persist
  @observable
  token = null;

  @persist
  @observable
  authType = "user-pass";

  constructor(uiStore) {
    this.uiStore = uiStore;
  }
  /**
   * returns logged in status
   */
  @action
  doLogin = async (email, password) => {
    const data = await login(email, password);
    this.authType = "user-pass";
    return this.loginHandling(data);
  };

  @action
  loginHandling = (data) => {
    if (typeof data !== "boolean") {
      const { user, token } = data;

      // if language is undefined set user language from
      // user langugage
      if (!this.uiStore.locale) {
        this.uiStore.setLocale(user.language);
      }

      this.user = user;
      this.token = token;

      this.isLoggedIn = true;
      return true;
    }
    return false;
  };

  @action
  startSSO = async (type) => {
    const returnTo = `${window.location.origin}${ROUTE_SSO_SESSION_CALLBACK.replace(":authType", type)}`;
    switch (type) {
      case "keycloak":
        window.location.href = `${API_URL}oidc/keycloak/login?returnTo=${returnTo}`;
        break;
      case "police":
        window.location.href = `${API_URL}oidc/police/login?returnTo=${returnTo}`;
        break;
      case "acmidm":
        window.location.href = `${API_URL}oauth2/acmidm/login?returnTo=${returnTo}`;
        break;
      default:
        console.warn(`Login strategy '${type}' not implemented`);
    }
  };

  @action
  doLoginSso = async ({ authType, sessionId }) => {
    const data = await loginSSO({ authType, sessionId });
    this.authType = authType;
    return this.loginHandling(data);
  };

  /**
   * returns logged in status
   */
  @action
  doLogout = async (returnToUrl) => {
    this.forceIsLoggedInFalse();
    await logout();
    this.ssoLogout();
  };

  ssoLogout = (returnToUrl) => {
    const returnTo = returnToUrl || `${window.location.origin}${ROUTE_LOGIN}`;
    if (this.authType === "keycloak") {
      window.location.href = `${API_URL}oidc/${this.authType}/logout?returnTo=${returnTo}`;
    } else if (this.authType === "acmidm") {
      window.location.href = `${API_URL}oauth2/${this.authType}/logout?returnTo=${returnTo}`;
    }
  };

  @action
  forceIsLoggedInFalse = () => {
    this.user = {};
    this.token = null;
    this.isLoggedIn = false;
  };

  @computed
  get authStrategies() {
    return AUTH_STRATEGIES_CONFIG.filter((strat) => AUTH_STRATEGIES.includes(strat.type));
  }
}

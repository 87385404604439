// root auth
export const ROUTE_BASE = "/";
export const ROUTE_SSO_SESSION_CALLBACK = ROUTE_BASE + "sso-session/:authType";
export const ROUTE_LOGIN = ROUTE_BASE + "login";
export const ROUTE_USERGROUPS = ROUTE_BASE + "usergroups";
export const ROUTE_ORGANIZATIONS = ROUTE_BASE + "organizations";
export const ROUTE_ORGANIZATIONS_ADD = ROUTE_ORGANIZATIONS + "/add";
export const ROUTE_ORGANIZATIONS_DETAILS = ROUTE_ORGANIZATIONS + "/details/:id";
// export const ROUTE_USERGROUPS_ADD = ROUTE_USERGROUPS + "/add";
export const ROUTE_USERGROUPS_DETAILS = ROUTE_USERGROUPS + "/details/:id";
export const ROUTE_USERS = ROUTE_BASE + "users";
export const ROUTE_USERS_ADD = ROUTE_USERS + "/add";
export const ROUTE_USERS_DETAILS = ROUTE_USERS + "/details/:id";
export const ROUTE_PROFILES = ROUTE_BASE + ":app/profiles";
export const ROUTE_PROFILES_DETAILS = ROUTE_PROFILES + "/details/:id";
// export const ROUTE_PERMISSIONS = ROUTE_BASE + ":app/permissions";
export const ROUTE_MUNICIPALITIES = ROUTE_BASE + ":app/municipalities";
export const ROUTE_MUNICIPALITIES_ADD = ROUTE_MUNICIPALITIES + "/add";
export const ROUTE_MUNICIPALITIES_DETAILS = ROUTE_MUNICIPALITIES + "/details/:id";
export const ROUTE_CRAB_ADDRESSES = ROUTE_BASE + ":app/crab";

export const ROUTE_SSO_GROUPS = ROUTE_BASE + "ssogroups";
export const ROUTE_SSO_GROUPS_ADD = ROUTE_SSO_GROUPS + "/add";
export const ROUTE_SSO_GROUPS_DETAILS = ROUTE_SSO_GROUPS + "/details/:id";

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { observer as hooksObserver } from "mobx-react-lite";
import { StoresContext } from "contexts";
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Input,
  Divider,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { styles, datePickerTheme } from "./MunicipalityDetailsStyles";

import { ROUTE_USERS, ROUTE_MUNICIPALITIES } from "../../../routes/RouteList";

import CustomDialog from "../customdialog/CustomDialog";

import messages from "./messages.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CrabAddresses = hooksObserver(({ match, intl: { formatMessage }, classes }) => {
  const {
    routingStore: { push },
    moduleStore: { municipalities: { importCrabAdressen } },
    applicationStore: { toggleSnackError },
  } = useContext(StoresContext);

  const [dialog, setDialog] = useState(false);
  const [errorImport, setErrorImport] = useState("");
  const [file, setFile] = useState(null);

  function handleOpen() {
    return setDialog(!dialog);
  }

  async function submitImport() {
    if (file) {
      try {
        await importCrabAdressen(file);
        setErrorImport(formatMessage(messages.municipalityimportmessagesuccess));
      } catch (e) {
        console.log("Error::municipality details import: ", e.toString());
        switch (e.response.status) {
          case 401:
            forceIsLoggedInFalse();
            break;
          default:
            toggleSnackError();
            console.log("TODO::municipality details import: We still need to catch the following error: ", e.response.status);
            break;
        }
      }
    }
  }

  function checkValidityImport() {
    let form = document.getElementById("formImport");
    form.reportValidity() ? submitImport() : null; // zie https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/reportValidity
  }

  return (
    <Paper className={classes.paperImport}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h6" color="inherit">
                {formatMessage(messages.crabimport)}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        <form id="formImport">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Button variant="contained" fullWidth component="label" className={classes.importBtn}>
                Selecteer CRAB bestand ...
                <input type="file" style={{ display: "none" }} onChange={(e) => setFile(e.target.files[0])} />
              </Button>
              {file && file.name}
            </Grid>
          </Grid>
          <Grid className={classes.formButtons} container direction="row-reverse" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.button} onClick={checkValidityImport}>
                {formatMessage(messages.municipalitydetailimport)}
              </Button>
            </Grid>
            <Grid item>
              <Button color="secondary" className={classes.button} onClick={() => push(ROUTE_MUNICIPALITIES)}>
                {formatMessage(messages.addmunicipalitycancel)}
              </Button>
            </Grid>
            <Grid item>
              <Typography>{errorImport}</Typography>
            </Grid>
          </Grid>
        </form>
      </div>
      <CustomDialog
        open={dialog}
        setopen={handleOpen}
        title={formatMessage(messages.municipalitydetaildialogtitle)}
        type={"danger"}
        confirm={formatMessage(messages.municipalitydetaildialogconfirm)}
        confirmaction={() => {}}
        cancel={formatMessage(messages.municipalitydetaildialogcancel)}
      >
        {formatMessage(messages.municipalitydetaildialogtext)}
      </CustomDialog>
    </Paper>
  );
});

export default injectIntl(withStyles(styles)(CrabAddresses));
